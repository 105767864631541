import { createSlice } from '@reduxjs/toolkit'
import { StateObservable } from 'redux-observable'

import { ProjectListInitialSlice } from '../Types'

const initialState: ProjectListInitialSlice = {
  projectLists: [],
  edit: false,
  projectList: null,
  loadingProjectList: false,
  loadingCreateProjectList: false,
  loadingDeleteProject: false,
  count: 0,
  next: '',
  previous: '',
  issueQuotation: null,
  issueQuotationDetailDraft: null,
  loadingCreateIssueQuotation: false,
  loadingIssueQuotation: false,
  projectListDetails: [],
  loadingProjectDetails: false,
  loadingCreate: false,
  loadingUpdate: false,
  projectDetail: null,
  LoadingProjectDetail: false,
  purchaseOrder: null,
  loadingPurchaseOrder: false,
  loadingUploadPurchaseOrder: false,
  orderReceipt: null,
  loadingOrderReceipt: false,
  loadingCreateOrderReceipt: false,
  loadingUploadPurchaseOrderPlacement: false,
  loadingUploadPurchaseOrderReceipt: false,
  purchaseOrderReceipt: null,
  loadingPurchaseOrderReceipt: false,
  loadingProjectDeliveryNoteApprove: false,
  ProjectDeliveryNoteApprove: null,
  loadingCreateProjectDeliveryNoteApprove: false,
  certificate: null,
  loadingCertificate: false,
  loadingCreateCertificate: false,
  artifact: null,
  loadingArtifact: false,
  loadingCreateArtifact: false,
  certificateComplete: null,
  loadingCertificateComplete: false,
  completingCertificate: false,
  loadingRequestFileDelete: false,
  loadingReferenceFileDelete: false,
  loadingUploadInspectionCertificateUpload: false,
  inspectionCertificateUpload: null,
  projectDetailInvoice: null,
  loadingCreateProjectIssueInvoice: false,
  loadingProjectDetailInvoice: false,
  loadingAutoJobCreateAndSendMail: false,
  loadingOrderReceiptAcceptance: false,
  isOrderReceiptAccepted: false,
  loadingDeliveryNoteAcceptance: false,
  isDeliveryNoteAccepted: false,
  loadingIssueQuotationPreview: false,
  autoCalculating: false,
  autoCalculateResult: [],
  combinedAutoCalculateResult: [],
  loadingUpdateDeliveryWithdrawal: false,
  loadingIssueQuotationDetailDraft: false,
  loadingProjectListSalesExport: false,
  caseWorkFlowHistories: [],
  loadingCaseWorkFlowHistory: false,
  loadingQuotationStatus: false,
  loadingInputTrackRecordStatus: false,
  loadingDeliveryItemUploadStatus: false,
  loadingPurchaseOrderUploadStatus: false,
  loadingCertificateUploadStatus: false,
  loadingCompleteCertificateStatus: false,
  loadingDeliveryReportStatus: false,
  loadingOrderReceiptStatus: false,
  loadingUserMadeInvoiceStatus: false,
  loadingCaseInvoiceStatus: false,
  loadingSinglePurchaseOrderReceipt: false,
}
export const ProjectList = createSlice({
  name: 'projectListReducer',
  initialState,
  reducers: {
    loadingProjectList: (state) => {
      state.loadingProjectList = true
    },
    getProjectListRequest: (state, action) => {
      state.loadingProjectList = true
    },
    getProjectListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingProjectList = false
      state.projectLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getProjectListFail: (state) => {
      state.loadingProjectList = false
    },
    //
    getProjectListDetailRequest: (state, action) => {
      state.loadingProjectDetails = true
    },
    getProjectListDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingProjectDetails = false
      state.projectListDetails = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getProjectListDetailFail: (state) => {
      state.loadingProjectDetails = false
    },
    //
    getProjectDetailListNextRequest: (state, action) => {
      state.loadingProjectDetails = true
    },
    getProjectDetailListPreviousRequest: (state, action) => {
      state.loadingProjectDetails = true
    },
    // delete  project
    deleteProjectRequest: (state, payload) => {
      state.loadingDeleteProject = true
    },
    deleteProjectSuccess: (state) => {
      state.loadingDeleteProject = false
    },
    deleteProjectFail: (state) => {
      state.loadingDeleteProject = false
    },
    // get quotation detail draft by id
    getQuotationDetailDraftByIdRequest: (state, action) => {
      state.loadingIssueQuotationDetailDraft = true
    },
    getQuotationDetailDraftByIdSuccess: (state, { payload }) => {
      state.loadingIssueQuotationDetailDraft = false
      state.issueQuotationDetailDraft = payload?.results
    },
    getQuotationDetailDraftByIdFail: (state) => {
      state.loadingIssueQuotationDetailDraft = false
    },
    // get quotation by id
    getQuotationByIdRequest: (state, action) => {
      state.loadingIssueQuotation = true
    },
    getQuotationByIdSuccess: (state, { payload }) => {
      state.loadingIssueQuotation = false
      state.issueQuotation = payload
    },
    getQuotationByIdFail: (state) => {
      state.loadingIssueQuotation = false
    },
    //update quotation
    updateQuotationRequest: (state, payload) => {
      state.edit = true
      state.loadingCreateIssueQuotation = true
    },
    updateQuotationSuccess: (state) => {
      state.loadingCreateIssueQuotation = false
    },
    updateQuotationFail: (state) => {
      state.loadingCreateIssueQuotation = false
    },
    clearQuotation: (state) => {
      state.issueQuotation = null
      state.loadingCreateIssueQuotation = false
    },
    clearQuotationDetailDraft: (state) => {
      state.issueQuotationDetailDraft = null
      state.loadingIssueQuotationDetailDraft = false
    },
    //
    //     getIssueQuotationPreviewByIdRequest:(state,payload)=>{
    // state.loadingIssueQuotationPreview=true;
    //     },
    // create quotation
    createQuotationRequest: (state, payload) => {
      state.loadingCreateIssueQuotation = true
    },
    createQuotationSuccess: (state) => {
      state.loadingCreateIssueQuotation = false
    },
    createQuotationFail: (state) => {
      state.loadingCreateIssueQuotation = false
    },
    autoCalculateRequest: (state, action) => {
      state.autoCalculating = true
    },
    autoCalculateSuccess: (state, action) => {
      const { data, index } = action.payload
      console.log(data, 'datadatadatadatadata')
      console.log(index, 'indexindexindexindexindex')

      state.autoCalculating = false
      state.autoCalculateResult[index] = data
      state.combinedAutoCalculateResult = state.autoCalculateResult.flat()
    },
    autoCalculateFail: (state) => {
      state.autoCalculateResult = []
      state.combinedAutoCalculateResult = []
      state.autoCalculating = false
    },
    clearAutoCalculate: (state) => {
      state.autoCalculateResult = []
      state.combinedAutoCalculateResult = []
      state.autoCalculating = false
    },
    //
    createProjectDetailsRequest: (state, payload) => {
      state.loadingCreate = true
    },
    createProjectDetailsSuccess: (state) => {
      state.loadingCreate = false
    },
    createProjectDetailsFail: (state) => {
      state.loadingCreate = false
    },
    //
    updateProjectDetailsRequest: (state, payload) => {
      state.loadingUpdate = true
    },
    updateProjectDetailsSuccess: (state) => {
      state.loadingUpdate = false
    },
    updateProjectDetailsFail: (state) => {
      state.loadingUpdate = false
    },
    clearDataProjectDetails: (state) => {
      state.projectDetail = null
      state.loadingUpdate = false
      state.loadingCreate = false
    },

    //
    getCaseDetailByIdRequest: (state, action) => {
      state.LoadingProjectDetail = true
    },
    getProjectDetailByIdRequest: (state, action) => {
      state.LoadingProjectDetail = true
    },
    getProjectDetailByIdSuccess: (state, { payload }) => {
      state.projectDetail = payload
      state.LoadingProjectDetail = false
    },
    getProjectDetailByIdFail: (state) => {
      state.projectDetail = null
      state.LoadingProjectDetail = false
    },
    //purchase order
    getPurchaseOrderByIdRequest: (state, { payload }) => {
      state.purchaseOrder = payload
    },
    getPurchaseOrderByIdSuccess: (state, { payload }) => {
      state.purchaseOrder = payload
      state.loadingPurchaseOrder = false
    },
    getPurchaseOrderByIdFail: (state) => {
      state.purchaseOrder = null
      state.loadingPurchaseOrder = false
    },
    //
    getPurchaseOrderReceiptByIdRequest: (state, { payload }) => {
      // state.purchaseOrderReceipt = payload
      state.loadingSinglePurchaseOrderReceipt = false
      state.loadingPurchaseOrderReceipt = true
    },
    getSinglePurchaseOrderReceiptByIdRequest: (state, { payload }) => {
      // state.purchaseOrderReceipt = payload
      state.loadingPurchaseOrderReceipt = false
      state.loadingSinglePurchaseOrderReceipt = true
    },
    getPurchaseOrderReceiptByIdSuccess: (state, { payload }) => {
      state.purchaseOrderReceipt = payload
      state.loadingPurchaseOrderReceipt = false
      state.loadingSinglePurchaseOrderReceipt = false
    },
    getPurchaseOrderReceiptByIdFail: (state) => {
      state.purchaseOrderReceipt = null
      state.loadingPurchaseOrder = false
    },
    //
    updatePurchaseOrderRequest: (state, payload) => {
      state.loadingUploadPurchaseOrder = true
    },
    updatePurchaseOrderSuccess: (state) => {
      state.loadingUploadPurchaseOrder = false
    },
    updatePurchaseOrderFail: (state) => {
      state.loadingUploadPurchaseOrder = false
    },
    clearPurchaseOrderDetails: (state) => {
      state.purchaseOrder = null
      state.purchaseOrderReceipt = null
      state.loadingUploadPurchaseOrder = false
    },
    //
    createPurchaseOrderUploadRequest: (state, payload) => {
      state.loadingUploadPurchaseOrder = true
    },
    createPurchaseOrderUploadSuccess: (state) => {
      state.loadingUploadPurchaseOrder = false
    },
    createPurchaseOrderUploadFail: (state) => {
      state.loadingUploadPurchaseOrder = false
    },
    // get order receipt by id
    getOrderReceiptByIdRequest: (state, action) => {
      state.loadingOrderReceipt = true
    },
    getOrderReceiptByIdSuccess: (state, { payload }) => {
      state.loadingOrderReceipt = false
      state.orderReceipt = payload
    },
    getOrderReceiptByIdFail: (state) => {
      state.loadingOrderReceipt = false
    },
    //update purchase order
    updateOrderReceiptRequest: (state, payload) => {
      state.edit = true
      state.loadingCreateOrderReceipt = true
    },
    updateOrderReceiptSuccess: (state) => {
      state.loadingCreateOrderReceipt = false
    },
    updateOrderReceiptFail: (state) => {
      state.loadingCreateOrderReceipt = false
    },
    createOrderReceiptRequest: (state, payload) => {
      state.loadingCreateOrderReceipt = true
    },
    createOrderReceiptSuccess: (state) => {
      state.loadingCreateOrderReceipt = false
    },
    createOrderReceiptFail: (state) => {
      state.loadingCreateOrderReceipt = false
    },
    clearOrderReceipt: (state) => {
      state.orderReceipt = null
      state.isOrderReceiptAccepted = false
    },
    sendOrderReceiptAcceptanceRequest: (state, payload) => {
      state.loadingOrderReceiptAcceptance = true
    },
    sendOrderReceiptAcceptanceSuccess: (state) => {
      state.loadingOrderReceiptAcceptance = false
      state.isOrderReceiptAccepted = true
    },
    sendOrderReceiptAcceptanceFail: (state) => {
      state.loadingOrderReceiptAcceptance = false
    },
    //
    updatePurchaseOrderReceiptRequest: (state, payload) => {
      state.loadingUploadPurchaseOrderReceipt = true
    },
    updatePurchaseOrderReceiptSuccess: (state) => {
      state.loadingUploadPurchaseOrderReceipt = false
    },
    updatePurchaseOrderReceiptFail: (state) => {
      state.loadingUploadPurchaseOrderReceipt = false
    },
    sendJobCreateAndMailRequest: (state, payload) => {
      state.loadingAutoJobCreateAndSendMail = true
    },
    sendJobCreateAndMailSuccess: (state) => {
      state.loadingAutoJobCreateAndSendMail = false
    },
    sendJobCreateAndMailFail: (state) => {
      state.loadingAutoJobCreateAndSendMail = false
    },

    // clearPurchaseOrderReceiptDetails: (state) => {
    //   state.purchaseOrderReceipt = null
    // },
    //
    updatePurchaseOrderPlacementRequest: (state, payload) => {
      state.loadingUploadPurchaseOrderPlacement = true
    },
    updatePurchaseOrderPlacementSuccess: (state) => {
      state.loadingUploadPurchaseOrderPlacement = false
    },
    updatePurchaseOrderPlacementFail: (state) => {
      state.loadingUploadPurchaseOrderPlacement = false
    },
    //
    createPurchaseOrderReceiptRequest: (state, payload) => {
      state.loadingUploadPurchaseOrderPlacement = true
    },
    createPurchaseOrderReceiptSuccess: (state) => {
      state.loadingUploadPurchaseOrderPlacement = false
    },
    createPurchaseOrderReceiptFail: (state) => {
      state.loadingUploadPurchaseOrderPlacement = false
    },
    //
    getProjectDeliveryNoteApproveByIdRequest: (state, action) => {
      state.loadingProjectDeliveryNoteApprove = true
    },
    getProjectDeliveryNoteApproveByIdSuccess: (state, { payload }) => {
      state.ProjectDeliveryNoteApprove = payload
      state.loadingProjectDeliveryNoteApprove = false
    },
    getProjectDeliveryNoteApproveByIdFail: (state) => {
      state.edit = false
      state.loadingProjectDeliveryNoteApprove = false
    },
    clearProjectDeliveryNoteApprove: (state) => {
      state.ProjectDeliveryNoteApprove = null
    },
    //
    createProjectDeliveryNoteApproveRequest: (state, payload) => {
      state.loadingCreateProjectDeliveryNoteApprove = true
    },
    createProjectDeliveryNoteApproveSuccess: (state) => {
      state.loadingCreateProjectDeliveryNoteApprove = false
    },
    createProjectDeliveryNoteApproveFail: (state) => {
      state.loadingCreateProjectDeliveryNoteApprove = false
    },
    //
    updateProjectDeliveryNoteApproveRequest: (state, payload) => {
      state.loadingCreateProjectDeliveryNoteApprove = true
    },
    updateProjectDeliveryNoteApproveSuccess: (state) => {
      state.loadingCreateProjectDeliveryNoteApprove = false
    },
    updateProjectDeliveryNoteApproveFail: (state) => {
      state.loadingCreateProjectDeliveryNoteApprove = false
    },
    sendDeliveryNoteAcceptanceRequest: (state, payload) => {
      state.loadingDeliveryNoteAcceptance = true
    },
    sendDeliveryNoteAcceptanceSuccess: (state) => {
      state.loadingDeliveryNoteAcceptance = false
      state.isDeliveryNoteAccepted = true
    },
    sendDeliveryNoteAcceptanceFail: (state) => {
      state.loadingDeliveryNoteAcceptance = false
    },
    // get Certificate by id
    getCertificateByIdRequest: (state, action) => {
      state.loadingCertificate = true
    },
    getCertificateByIdSuccess: (state, { payload }) => {
      state.loadingCertificate = false
      state.certificate = payload
    },
    getCertificateByIdFail: (state) => {
      state.loadingCertificate = false
    },
    //update certificate
    updateCertificateRequest: (state, payload) => {
      state.edit = true
      state.loadingCreateCertificate = true
    },
    updateCertificateSuccess: (state) => {
      state.loadingCreateCertificate = false
    },
    updateCertificateFail: (state) => {
      state.loadingCreateCertificate = false
    },
    clearCertificate: (state) => {
      state.certificate = null
      state.edit = false
      //
    },
    // get order receipt by id
    getArtifactByIdRequest: (state, action) => {
      state.loadingArtifact = true
    },
    getArtifactByIdSuccess: (state, { payload }) => {
      state.loadingArtifact = false
      state.artifact = payload
    },
    getArtifactByIdFail: (state) => {
      state.loadingArtifact = false
    },
    //update purchase order
    updateArtifactRequest: (state, payload) => {
      state.loadingCreateArtifact = true
    },
    updateArtifactSuccess: (state) => {
      state.loadingCreateArtifact = false
    },
    updateArtifactFail: (state) => {
      state.loadingCreateArtifact = false
    },
    createArtifactRequest: (state, payload) => {
      state.loadingCreateArtifact = true
    },
    createArtifactSuccess: (state) => {
      state.loadingCreateArtifact = false
    },
    createArtifactFail: (state) => {
      state.loadingCreateArtifact = false
    },
    clearArtifact: (state) => {
      state.artifact = null
      //
    },
    // get certificate complete by id
    getCertificateCompleteByIdRequest: (state, action) => {
      state.loadingCertificateComplete = true
    },
    getCertificateCompleteByIdSuccess: (state, { payload }) => {
      state.loadingCertificateComplete = false
      state.certificateComplete = payload
    },
    getCertificateCompleteByIdFail: (state) => {
      state.loadingCertificateComplete = false
    },
    clearCertificateComplete: (state) => {
      state.certificateComplete = null
    },
    // create quotation
    completeCertificateRequest: (state, payload) => {
      state.completingCertificate = true
    },
    completeCertificateFinish: (state) => {
      state.completingCertificate = false
    },
    //
    deleteReferenceFileByIdRequest: (state, action) => {
      state.loadingReferenceFileDelete = true
    },
    deleteReferenceFileByIdSuccess: (state) => {
      state.loadingReferenceFileDelete = false
    },
    deleteReferenceFileByIdFail: (state) => {
      state.loadingReferenceFileDelete = false
    },
    //
    deleteRequestFileByIdRequest: (state, action) => {
      state.loadingRequestFileDelete = true
    },
    deleteRequestFileByIdSuccess: (state) => {
      state.loadingRequestFileDelete = false
    },
    deleteRequestFileByIdFail: (state) => {
      state.loadingRequestFileDelete = false
    },
    //
    updateInspectionCertificateUploadRequest: (state, payload) => {
      state.loadingUploadInspectionCertificateUpload = true
    },
    updateInspectionCertificateUploadSuccess: (state) => {
      state.loadingUploadInspectionCertificateUpload = false
    },
    updateInspectionCertificateUploadFail: (state) => {
      state.loadingUploadInspectionCertificateUpload = false
    },
    clearInspectionCertificateUploadDetails: (state) => {
      state.inspectionCertificateUpload = null
    },
    //
    createInspectionCertificateUploadRequest: (state, payload) => {
      state.loadingUploadInspectionCertificateUpload = true
    },
    createInspectionCertificateUploadSuccess: (state) => {
      state.loadingUploadInspectionCertificateUpload = false
    },
    createInspectionCertificateUploadFail: (state) => {
      state.loadingUploadInspectionCertificateUpload = false
    },
    //
    getInspectionCertificateByIdRequest: (state, action) => {
      state.loadingUploadInspectionCertificateUpload = true
    },
    getInspectionCertificateByIdSuccess: (state, { payload }) => {
      state.loadingUploadInspectionCertificateUpload = false
      state.inspectionCertificateUpload = payload
    },
    getInspectionCertificateByIdFail: (state) => {
      state.loadingUploadInspectionCertificateUpload = false
    },
    //
    //update project detail invoice
    createProjectIssueInvoiceRequest: (state, payload) => {
      state.loadingCreateProjectIssueInvoice = true
    },
    createProjectIssueInvoiceSuccess: (state) => {
      state.loadingCreateProjectIssueInvoice = false
    },
    createProjectIssueInvoiceFail: (state) => {
      state.loadingCreateProjectIssueInvoice = false
    },
    //
    getUserMadeInvoiceStatusRequest: (state, payload) => {
      state.loadingUserMadeInvoiceStatus = true
    },
    getUserMadeInvoiceStatusFinish: (state) => {
      state.loadingUserMadeInvoiceStatus = false
    },
    //
    getCaseInvoiceStatusRequest: (state, payload) => {
      state.loadingCaseInvoiceStatus = true
    },
    getCaseInvoiceStatusFinish: (state) => {
      state.loadingCaseInvoiceStatus = false
    },

    updateProjectIssueInvoiceRequest: (state, payload) => {
      state.loadingCreateProjectIssueInvoice = true
    },
    updateProjectIssueInvoiceSuccess: (state) => {
      state.loadingCreateProjectIssueInvoice = false
    },
    updateProjectIssueInvoiceFail: (state) => {
      state.loadingCreateProjectIssueInvoice = false
    },
    clearProjectDetailInvoice: (state) => {
      state.projectDetailInvoice = null
      state.loadingCreateProjectIssueInvoice = false
    },

    // get issue invoice by job detail id
    getProjectDetailInvoiceByIdRequest: (state, action) => {
      state.edit = true
      state.loadingProjectDetailInvoice = true
    },
    getProjectDetailInvoiceByIdSuccess: (state, { payload }) => {
      state.projectDetailInvoice = payload
      state.loadingProjectDetailInvoice = false
    },
    getProjectDetailInvoiceByIdFail: (state) => {
      state.edit = false
      state.loadingProjectDetailInvoice = false
    },
    //delivery withdrawal
    updateDeliveryWithdrawalRequest: (state, payload) => {
      state.loadingUpdateDeliveryWithdrawal = true
    },
    updateDeliveryWithdrawalSuccess: (state) => {
      state.loadingUpdateDeliveryWithdrawal = false
    },
    updateDeliveryWithdrawalFail: (state) => {
      state.loadingUpdateDeliveryWithdrawal = false
    },
    //clear action here

    clearHardResetProjectList: (state) => {
      return initialState
    },
    //project list sales export
    projectListSalesExportRequest: (state, action) => {
      state.loadingProjectListSalesExport = true
    },
    projectListSalesExportSuccess: (state) => {
      state.loadingProjectListSalesExport = false
    },
    projectListSalesExportFail: (state) => {
      state.loadingProjectListSalesExport = false
    },
    //case workflow history
    getProjectWorkFlowHistoryRequest: (state, payload) => {
      state.loadingCaseWorkFlowHistory = true
    },
    getProjectWorkFlowHistorySuccess: (state, { payload }) => {
      state.loadingCaseWorkFlowHistory = false
      state.caseWorkFlowHistories = payload
    },
    getProjectWorkFlowHistoryFail: (state) => {
      state.loadingCaseWorkFlowHistory = false
    },
    getQuotationStatusRequest: (state, payload) => {
      state.loadingQuotationStatus = true
    },
    getQuotationStatusFinished: (state) => {
      state.loadingQuotationStatus = false
    },
    getInputTrackRecordStatusRequest: (state, payload) => {
      state.loadingInputTrackRecordStatus = true
    },
    getInputTrackRecordStatusFinished: (state) => {
      state.loadingInputTrackRecordStatus = false
    },
    getDeliveryItemUploadStatusRequest: (state, payload) => {
      state.loadingDeliveryItemUploadStatus = true
    },
    getDeliveryItemUploadStatusFinished: (state) => {
      state.loadingDeliveryItemUploadStatus = false
    },
    getPurchaseOrderUploadStatusRequest: (state, payload) => {
      state.loadingPurchaseOrderUploadStatus = true
    },
    getPurchaseOrderUploadStatusFinished: (state) => {
      state.loadingPurchaseOrderUploadStatus = false
    },
    getCertificateUploadStatusRequest: (state, payload) => {
      state.loadingCertificateUploadStatus = true
    },
    getCertificateUploadStatusFinished: (state) => {
      state.loadingCertificateUploadStatus = false
    },
    getCompleteCertificateStatusRequest: (state, payload) => {
      state.loadingCompleteCertificateStatus = true
    },
    getCompleteCertificateStatusFinished: (state) => {
      state.loadingCompleteCertificateStatus = false
    },
    getDeliveryReportStatusRequest: (state, payload) => {
      state.loadingDeliveryReportStatus = true
    },
    getDeliveryReportStatusFinished: (state) => {
      state.loadingDeliveryReportStatus = false
    },
    getOrderReceiptStatusRequest: (state, payload) => {
      state.loadingOrderReceiptStatus = true
    },
    getOrderReceiptStatusFinished: (state) => {
      state.loadingOrderReceiptStatus = false
    },
  },
})
export const {
  loadingProjectList,
  getProjectListSuccess,
  getProjectListFail,
  getProjectListRequest,
  getProjectListDetailSuccess,
  getProjectListDetailFail,
  getProjectDetailListNextRequest,
  getProjectDetailListPreviousRequest,
  deleteProjectRequest,
  deleteProjectSuccess,
  deleteProjectFail,
  //quotation
  getQuotationByIdRequest,
  getQuotationByIdSuccess,
  getQuotationStatusRequest,
  getQuotationStatusFinished,
  getQuotationByIdFail,
  createQuotationRequest,
  createQuotationSuccess,
  createQuotationFail,
  updateQuotationRequest,
  updateQuotationSuccess,
  updateQuotationFail,
  clearQuotation,
  clearQuotationDetailDraft,
  getQuotationDetailDraftByIdRequest,
  getQuotationDetailDraftByIdSuccess,
  getQuotationDetailDraftByIdFail,
  //Input track record Nav
  getInputTrackRecordStatusRequest,
  getInputTrackRecordStatusFinished,
  //
  getDeliveryItemUploadStatusRequest,
  getDeliveryItemUploadStatusFinished,
  //
  getCertificateUploadStatusRequest,
  getCertificateUploadStatusFinished,
  //
  getCaseInvoiceStatusRequest,
  getCaseInvoiceStatusFinish,
  //
  getCompleteCertificateStatusRequest,
  getCompleteCertificateStatusFinished,
  //
  getPurchaseOrderUploadStatusRequest,
  getPurchaseOrderUploadStatusFinished,
  //
  getDeliveryReportStatusRequest,
  getDeliveryReportStatusFinished,
  //
  getOrderReceiptStatusRequest,
  getOrderReceiptStatusFinished,
  //
  createProjectDetailsRequest,
  createProjectDetailsSuccess,
  createProjectDetailsFail,
  //
  getUserMadeInvoiceStatusRequest,
  getUserMadeInvoiceStatusFinish,
  //
  getCaseDetailByIdRequest,
  getProjectDetailByIdRequest,
  getProjectDetailByIdSuccess,
  getProjectDetailByIdFail,
  //
  updateProjectDetailsRequest,
  updateProjectDetailsSuccess,
  updateProjectDetailsFail,
  clearDataProjectDetails,
  //
  getProjectListDetailRequest,
  //
  getPurchaseOrderByIdRequest,
  getPurchaseOrderByIdSuccess,
  getPurchaseOrderByIdFail,
  //
  updatePurchaseOrderRequest,
  updatePurchaseOrderSuccess,
  updatePurchaseOrderFail,
  clearPurchaseOrderDetails,
  //order receipt
  getOrderReceiptByIdRequest,
  getOrderReceiptByIdSuccess,
  getOrderReceiptByIdFail,
  updateOrderReceiptRequest,
  updateOrderReceiptSuccess,
  updateOrderReceiptFail,
  createOrderReceiptRequest,
  createOrderReceiptSuccess,
  createOrderReceiptFail,
  clearOrderReceipt,
  sendOrderReceiptAcceptanceRequest,
  sendOrderReceiptAcceptanceSuccess,
  sendOrderReceiptAcceptanceFail,
  //
  updatePurchaseOrderReceiptRequest,
  updatePurchaseOrderReceiptSuccess,
  updatePurchaseOrderReceiptFail,
  //
  updatePurchaseOrderPlacementRequest,
  updatePurchaseOrderPlacementSuccess,
  updatePurchaseOrderPlacementFail,
  //
  createPurchaseOrderReceiptRequest,
  createPurchaseOrderReceiptSuccess,
  createPurchaseOrderReceiptFail,
  //
  getPurchaseOrderReceiptByIdRequest,
  getSinglePurchaseOrderReceiptByIdRequest,
  getPurchaseOrderReceiptByIdSuccess,
  getPurchaseOrderReceiptByIdFail,
  //
  getProjectDeliveryNoteApproveByIdRequest,
  getProjectDeliveryNoteApproveByIdSuccess,
  getProjectDeliveryNoteApproveByIdFail,
  clearProjectDeliveryNoteApprove,
  //
  createProjectDeliveryNoteApproveRequest,
  createProjectDeliveryNoteApproveSuccess,
  createProjectDeliveryNoteApproveFail,
  //
  updateProjectDeliveryNoteApproveRequest,
  updateProjectDeliveryNoteApproveSuccess,
  updateProjectDeliveryNoteApproveFail,
  //
  sendDeliveryNoteAcceptanceRequest,
  sendDeliveryNoteAcceptanceSuccess,
  sendDeliveryNoteAcceptanceFail,
  //certificate
  getCertificateByIdRequest,
  getCertificateByIdSuccess,
  getCertificateByIdFail,
  updateCertificateRequest,
  updateCertificateSuccess,
  updateCertificateFail,
  clearCertificate,
  //artifact
  getArtifactByIdRequest,
  getArtifactByIdSuccess,
  getArtifactByIdFail,
  createArtifactRequest,
  createArtifactSuccess,
  createArtifactFail,
  updateArtifactRequest,
  updateArtifactSuccess,
  updateArtifactFail,
  clearArtifact,
  //certificate complete
  getCertificateCompleteByIdRequest,
  getCertificateCompleteByIdSuccess,
  getCertificateCompleteByIdFail,
  completeCertificateRequest,
  completeCertificateFinish,
  clearCertificateComplete,
  //
  deleteReferenceFileByIdRequest,
  deleteReferenceFileByIdSuccess,
  deleteReferenceFileByIdFail,
  //
  deleteRequestFileByIdRequest,
  deleteRequestFileByIdSuccess,
  deleteRequestFileByIdFail,
  //
  createPurchaseOrderUploadRequest,
  createPurchaseOrderUploadSuccess,
  createPurchaseOrderUploadFail,
  //
  updateInspectionCertificateUploadRequest,
  updateInspectionCertificateUploadSuccess,
  updateInspectionCertificateUploadFail,
  clearInspectionCertificateUploadDetails,
  //
  createInspectionCertificateUploadRequest,
  createInspectionCertificateUploadSuccess,
  createInspectionCertificateUploadFail,
  //
  getInspectionCertificateByIdRequest,
  getInspectionCertificateByIdSuccess,
  getInspectionCertificateByIdFail,
  //
  createProjectIssueInvoiceRequest,
  createProjectIssueInvoiceSuccess,
  createProjectIssueInvoiceFail,
  //
  updateProjectIssueInvoiceRequest,
  updateProjectIssueInvoiceSuccess,
  updateProjectIssueInvoiceFail,
  clearProjectDetailInvoice,
  //
  getProjectDetailInvoiceByIdRequest,
  getProjectDetailInvoiceByIdSuccess,
  getProjectDetailInvoiceByIdFail,
  //
  sendJobCreateAndMailRequest,
  sendJobCreateAndMailSuccess,
  sendJobCreateAndMailFail,
  //
  autoCalculateRequest,
  autoCalculateSuccess,
  autoCalculateFail,
  clearAutoCalculate,
  //update delivery withdrawal
  updateDeliveryWithdrawalRequest,
  updateDeliveryWithdrawalSuccess,
  updateDeliveryWithdrawalFail,
  //hard reset project data
  clearHardResetProjectList,
  //project list sales export
  projectListSalesExportRequest,
  projectListSalesExportSuccess,
  projectListSalesExportFail,
  //case workflow history
  getProjectWorkFlowHistoryRequest,
  getProjectWorkFlowHistorySuccess,
  getProjectWorkFlowHistoryFail,
} = ProjectList.actions
export default ProjectList.reducer
