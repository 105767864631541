import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardHeader,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import dayjs from 'dayjs'
//
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useField,
} from 'formik'
import * as Yup from 'yup'

import { authSelector } from '../../Login/Redux/selector'
import AppAutoComplete from '../AppAutoComplete/AppAutoComplete'
import AppButton from '../AppButton/AppButton'
import AppTextField from '../AppTextField/AppTextField'
import { useAppDispatch, useAppSelector } from '../CustomHooks/appHooks'
import FocusElementOnInvalidValidation from '../FocusElementIfInvalid/FocusElementOnInvalidValidation'
import { workflowSelector } from './Redux/selector'
import {
  clearWorkflow,
  getUsersRequest,
  getWorkflowRequest,
  updateWorkflowRequest,
} from './Redux/workflowSlice'
import {
  Workflow,
  WorkflowAction,
  WorkflowActionPayload,
  WorkflowDetail,
} from './Types'
import {
  WORKFLOW_ACTION,
  WORKFLOW_STATUS,
  WORKFLOW_DETAIL_STATUS,
} from './constants'

export interface workFlowProps {
  id: number
  purchaseOrderCancel?: boolean
  reportRejected?: boolean
}

interface WorkflowFormValues {
  comment: string
  approver: string
}

const ApplicateUserInput = () => {
  const [useEmail, setUseEmail] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authSelector)
  const { users, loadingUser } = useAppSelector(workflowSelector)
  const [query, setQuery] = useState<string>('')
  const [field, meta, helpers] = useField('approver')

  useEffect(() => {
    dispatch(getUsersRequest(query))
  }, [query])

  return (
    <>
      <Grid item xs={5}>
        <AppTextField
          name="applicator-display"
          label={t('workFlow.applicator')}
          isNotFormik
          value={user!.full_name}
          disabled
        />
      </Grid>
      <Grid item xs={5}>
        {!useEmail ? (
          <AppAutoComplete
            name="approver"
            // required
            options={users}
            getOptionLabel={(option: any) =>
              option.id ? `${option.last_name} ${option.first_name}` : option
            }
            label={t('workFlow.approver')}
            onChange={(event: any, value: any) => {
              helpers.setValue(value?.email || '')
            }}
            onBlur={field.onBlur}
            inputOnchange={(e: any) => setQuery(e.target.value)}
            loading={loadingUser}
            value={field.value}
            //remove console waring error here
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        ) : (
          <AppTextField
            name="approver"
            type="email"
            label={t('workFlow.approver')}
            // required
            isNotFormik
            onChange={field.onChange}
            helperText={meta.touched && meta.error}
            error={meta.touched && !!meta.error}
            value={field.value}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={<Checkbox />}
          onChange={() => setUseEmail(!useEmail)}
          label={t('workFlow.useEmail')}
        />
      </Grid>
    </>
  )
}

const UserDisplay = React.memo(
  ({ workflow }: { workflow: Workflow | null }) => {
    if (!workflow) return null
    return (
      <>
        {workflow.workflow_details
          .filter(({ stage }) => stage === workflow.stage)
          .map(({ id, user_fullname, user_email }, index) => (
            //key added for avoid unique key props console error
            <Grid item xs={5} key={index}>
              <AppTextField
                key={`detail-user-${id}`}
                isNotFormik
                value={user_fullname || user_email}
                disabled
              />
            </Grid>
          ))}
      </>
    )
  }
)

const WorkFlowPanel = ({
  id,
  purchaseOrderCancel,
  reportRejected,
}: workFlowProps) => {
  const { workflow, loading } = useAppSelector(workflowSelector)
  const { user } = useAppSelector(authSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id) {
      dispatch(getWorkflowRequest(id))
    }
    return () => {
      dispatch(clearWorkflow())
    }
  }, [dispatch, id])

  const { t } = useTranslation()
  let remaining_number_of_client_approvers = workflow?.workflow_details.filter(
    (workflow_detail) =>
      workflow_detail.stage === 2 && workflow_detail.status === 1
  ).length

  const control = React.useMemo(() => {
    if (!workflow)
      return {
        canApplicate: false,
        canUnApplicate: false,
        canApproveReject: false,
        canCancel: false,
      }
    else {
      return {
        canApplicate: workflow.status === WORKFLOW_STATUS.PENDING,
        canUnApplicate:
          workflow.status === WORKFLOW_STATUS.APPROVING &&
          workflow.progress === 0 &&
          workflow.workflow_details[0]?.user_email === user?.email,
        canApproveReject:
          workflow.status === WORKFLOW_STATUS.APPROVING &&
          workflow.workflow_details[workflow.progress + 1]?.user_email ===
            user?.email,
        canCancel:
          workflow.status === WORKFLOW_STATUS.APPROVING &&
          workflow.stage !== 2 &&
          workflow.workflow_details[0]?.user_email === user?.email,
      }
    }
  }, [workflow, user])

  const handleCommit = (
    { comment, approver }: WorkflowFormValues,
    { resetForm }: FormikHelpers<WorkflowFormValues>
  ) => {
    const action = document.activeElement!.getAttribute('name')
    if (action === 'approver') return
    const body: WorkflowActionPayload = {
      comment,
      version: workflow!.version,
    }

    if (action === WORKFLOW_ACTION.APPLICATE) {
      body.workflow_details = [
        { user_email: user!.email },
        { user_email: approver },
      ]
    }

    dispatch(
      updateWorkflowRequest({ id, body, action: action as WorkflowAction })
    )
    resetForm()
  }

  const schema = Yup.object().shape({
    approver: Yup.string()
      .email(t('validationMessage.email'))
      .required(t('validationMessage.required')),
    comment: Yup.string(),
  })

  return (
    <>
      {/* //-------------------------------------------approval workflow----------------------- */}
      <Card sx={{ mb: 2 }}>
        <CardHeader
          title={
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {t('workFlow.approvalWorkFlow')}
            </Typography>
          }
          sx={{
            backgroundColor: '#f57c00',
            height: '28px',
          }}
        />
        <Formik
          initialValues={{ approver: '', comment: '' }}
          onSubmit={handleCommit}
          validationSchema={control.canApplicate ? schema : undefined}
        >
          <Form autoComplete="off">
            <Grid
              container
              spacing={3}
              sx={{ p: 5 }}
              alignItems="top"
              alignContent="center"
            >
              {control.canApplicate ? (
                <ApplicateUserInput />
              ) : (
                <UserDisplay workflow={workflow} />
              )}
              <Grid item xs={10}>
                <Field name="comment">
                  {({ field, form: { errors, touched }, meta }: FieldProps) => (
                    <AppTextField
                      // xs={7}
                      name="comment"
                      placeholder={t('workFlow.comment')}
                      label={t('workFlow.comment')}
                      // focusElement={focusButton}
                      multiline
                      rows={4}
                      InputProps={{
                        style: {
                          paddingLeft: '15px',
                          paddingRight: '5px',
                        },
                      }}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={10}>
                <Stack spacing={2} direction="row">
                  {control.canApplicate && !reportRejected && (
                    <AppButton
                      size="large"
                      variant="contained"
                      name="applicate"
                      submit={true}
                      loading={loading}
                      // type="submit"
                      disabled={purchaseOrderCancel}
                    >
                      {t('workFlow.applicate')}
                    </AppButton>
                  )}
                  {control.canUnApplicate && (
                    <AppButton
                      size="large"
                      variant="contained"
                      name="unapplicate"
                      submit={true}
                      loading={loading}
                      // type="submit"
                      disabled={purchaseOrderCancel}
                    >
                      {t('workFlow.unApplicate')}
                    </AppButton>
                  )}
                  {control.canApproveReject && (
                    <>
                      <AppButton
                        size="large"
                        variant="contained"
                        name="approve"
                        submit={true}
                        loading={loading}
                        disabled={purchaseOrderCancel}
                      >
                        {remaining_number_of_client_approvers === 2
                          ? t(`workFlow.status.APPROVINGREQUEST`)
                          : remaining_number_of_client_approvers === 1
                          ? t(`workFlow.status.ORDER`)
                          : t('workFlow.approve')}
                      </AppButton>
                      <AppButton
                        size="large"
                        variant="contained"
                        name="reject"
                        submit={true}
                        loading={loading}
                        disabled={purchaseOrderCancel}
                      >
                        {t('workFlow.reject')}
                      </AppButton>
                    </>
                  )}
                  {control.canCancel && (
                    <AppButton
                      size="large"
                      variant="contained"
                      name="cancel"
                      submit={true}
                      disabled={purchaseOrderCancel}
                      sx={{ display: 'none' }}
                    >
                      {t('workFlow.cancel')}
                    </AppButton>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <FocusElementOnInvalidValidation />
          </Form>
        </Formik>
      </Card>
    </>
  )
}

export default WorkFlowPanel
