import React, { useState, useRef, useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, Grid } from '@mui/material'

import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'

import { alertInfoAction } from '../../../CommonAppRedux/commonAppSlice'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../../ReusableComponent/CommonReportTable/CommonReportTable'
import CompanySelect from '../../../ReusableComponent/CompanySelect/CompanySelect'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import AddNewInvoiceFormBtn from '../../../ReusableComponent/MultipleUploadScreen/AddNewInvoiceFormBtn'
import CardTitle from '../../../ReusableComponent/MultipleUploadScreen/CardTitle'
import FileUploadAndWithdrawBtn from '../../../ReusableComponent/MultipleUploadScreen/FileUploadAndWithdrawBtn'
import {
  cardStyles,
  datePickerStyles,
  gridStyles,
} from '../../../ReusableComponent/MultipleUploadScreen/styles'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { downloadFile } from '../../../Utils/AppFunction'
import messages from '../../../Utils/ValidationMessage'
import { query } from '../../../Utils/globalTypes'
import { company } from '../../JobList/Types'
import {
  clearHardResetProjectList,
  clearPurchaseOrderDetails,
  createPurchaseOrderReceiptRequest,
  getPurchaseOrderReceiptByIdRequest,
  getSinglePurchaseOrderReceiptByIdRequest,
  updatePurchaseOrderReceiptRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'

export interface orderReceptUpload {
  company: company | null
  issue_date: string | Date | null
  amount: number
  file: string | File | null | any
  url: string
  version?: number
  id?: number
  withdrawal_flag: boolean
}

interface multipleOrderReceiptUploadProps {
  order_receipt_details: orderReceptUpload[]
}

const OrderRecept = ({ showInReportScreen }: any) => {
  const { purchaseOrderReceipt, loadingUploadPurchaseOrderPlacement } =
    useAppSelector(projectListSelector)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const edit = searchParams.get('edit')
  const [isWithdraw, setIsWithdraw] = useState(false)
  const orderReceiptId = searchParams.get('purchase-order-receipt-id')
  const reportScreenId = searchParams.get('order-receipt-id')
  const { state } = location
  const previousUrl = state?.path

  //all reducer action and clear function implement start
  useLayoutEffect(() => {
    if (showInReportScreen && reportScreenId && !orderReceiptId) {
      dispatch(getSinglePurchaseOrderReceiptByIdRequest(showInReportScreen))
    } else if (orderReceiptId && edit && !showInReportScreen) {
      dispatch(getPurchaseOrderReceiptByIdRequest(orderReceiptId))
    } else {
      dispatch(clearPurchaseOrderDetails())
      dispatch(clearHardResetProjectList())
    }
    return () => {
      dispatch(clearPurchaseOrderDetails())
      dispatch(clearHardResetProjectList())
    }
  }, [
    orderReceiptId,
    location,
    dispatch,
    edit,
    showInReportScreen,
    reportScreenId,
  ])

  //all reducer action and clear function implement End

  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    order_receipt_details: Yup.array().of(
      Yup.object().shape(
        {
          issue_date: Yup.mixed().required(t(messages.required)),
          url: Yup.string().when(['file'], ([file], schema, context) => {
            return file === null
              ? schema
                  .required(t(messages.required))
                  .url(t(messages.invalidUrl))
              : schema.url(t(messages.invalidUrl))
          }),
          file: Yup.mixed().when(['url'], ([url], schema, context) => {
            return url === undefined
              ? schema
                  .required(t(messages.required))
                  .test(
                    'fileType',
                    t(messages.invalidFileType),
                    (value: any) => {
                      if (!value) {
                        return true
                      }
                      return (
                        allowedTypes.includes(value.type) ||
                        allowedTypes.includes('')
                      )
                    }
                  )
              : schema.nullable()
          }),
        },
        [['url', 'file']]
      )
    ),
  })

  // const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']
  const [useMadeOrderReceiptObject, setUserMadeOrderReceiptObject] =
    useState<any>(null)

  // For the Case-Invoice Screen's
  useLayoutEffect(() => {
    if (purchaseOrderReceipt && typeof purchaseOrderReceipt === 'object') {
      setUserMadeOrderReceiptObject(purchaseOrderReceipt)
    }
  }, [purchaseOrderReceipt])
  const allowedTypes = ['']
  const initialOrderReceiptData: orderReceptUpload = {
    id: useMadeOrderReceiptObject?.id ?? undefined,
    company: useMadeOrderReceiptObject?.company ?? null,
    issue_date:
      useMadeOrderReceiptObject?.issue_date ?? dateFormatter(new Date()),
    amount: useMadeOrderReceiptObject?.amount ?? 0,
    file: useMadeOrderReceiptObject?.file ?? '',
    url: useMadeOrderReceiptObject?.url ?? '',
    version: useMadeOrderReceiptObject?.version ?? 1,
    withdrawal_flag: useMadeOrderReceiptObject?.withdrawal_flag ?? false,
  }
  const initialState: multipleOrderReceiptUploadProps = {
    order_receipt_details:
      purchaseOrderReceipt && purchaseOrderReceipt.length > 0
        ? purchaseOrderReceipt
        : [initialOrderReceiptData],
  }

  const nav = useNavigate()

  const handleWithdrawOrderReceipt = async (value: orderReceptUpload) => {
    dispatch(
      updatePurchaseOrderReceiptRequest({
        id: value?.id,
        orderReceiptId: orderReceiptId,
        values: {
          ...value,
          withdrawal_flag: true,
          company: value?.company?.id ?? '',
          issue_date: value?.issue_date ?? '',
          version: value?.version,
        },
        previousUrl,
        nav,
      })
    )
  }

  const onSubmit = (values: multipleOrderReceiptUploadProps, actions: any) => {
    const firstOrderReceiptDetail = values.order_receipt_details[0]
    if (!edit || (edit && !firstOrderReceiptDetail.id)) {
      const updateValue = {
        ...firstOrderReceiptDetail,
        order_receipt: Number(orderReceiptId),
        company: firstOrderReceiptDetail?.company?.id ?? '',
        issue_date: firstOrderReceiptDetail?.issue_date ?? '',
      }
      dispatch(
        createPurchaseOrderReceiptRequest({
          values: { updateValue },
          orderReceiptId: orderReceiptId,
          previousUrl,
          nav,
          actions,
        })
      )
    } else {
      dispatch(
        updatePurchaseOrderReceiptRequest({
          id: firstOrderReceiptDetail.id,
          orderReceiptId: orderReceiptId,
          values: {
            ...firstOrderReceiptDetail,
            company: firstOrderReceiptDetail?.company?.id ?? '',
            issue_date: firstOrderReceiptDetail?.issue_date ?? '',
            version: firstOrderReceiptDetail?.version,
            withdrawal_flag: firstOrderReceiptDetail?.withdrawal_flag
              ? firstOrderReceiptDetail?.withdrawal_flag
              : isWithdraw
              ? isWithdraw
              : false,
          },
          previousUrl,
          nav,
          actions,
        })
      )
    }
  }

  //focus elements
  const focus_SaveBtn = useRef(null)

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)

  const DETAIL_NAME = `order_receipt_details`

  const isAllOrderReceiptWithdrawed: boolean | null = useMemo(() => {
    return (
      purchaseOrderReceipt &&
      purchaseOrderReceipt?.length > 0 &&
      purchaseOrderReceipt?.every(
        (invc: orderReceptUpload) => invc.withdrawal_flag
      )
    )
  }, [purchaseOrderReceipt])

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          submitForm,
          setValues,
        }) => {
          const handleSave = () => {
            setIsWithdraw(false)
            submitForm()
          }
          const handleDownload = () => {
            if (values.order_receipt_details[0]?.file === null) {
              dispatch(alertInfoAction(t(messages.doesntExist)))
            } else {
              downloadFile(values.order_receipt_details[0]?.file)
            }
          }
          const buttonConfigs = [
            {
              label: 'common.upload',
              handler: handleSave,
              focusBtn: focus_SaveBtn,
              isShowAlert: true,
              loading: loadingUploadPurchaseOrderPlacement,
              message: t('confirmMessage.uploadAndLock'),
            },
            {
              label: 'common.download',
              handler: handleDownload,
            },
          ]

          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }

          return (
            <>
              {!showInReportScreen && (
                <Grid item sx={{ mb: 4 }}>
                  <SaveFooterComponent
                    handleClick={handleButtonAction}
                    buttonConfigs={buttonConfigs}
                  />
                </Grid>
              )}

              <CardTitle name={t('uploadRecept.orderReceipt.name')} />
              <AddNewInvoiceFormBtn
                displayAddButton={isAllOrderReceiptWithdrawed}
                onAddButtonClick={() => {
                  if (
                    purchaseOrderReceipt &&
                    values.order_receipt_details.length <=
                      purchaseOrderReceipt?.length //to only add the form once
                  ) {
                    // Update Formik's values with the new object at the beginning
                    setValues({
                      ...values,
                      order_receipt_details: [
                        initialOrderReceiptData,
                        ...values.order_receipt_details,
                      ],
                    })
                  }
                }}
              />
              <FieldArray
                name={DETAIL_NAME}
                render={() => (
                  <React.Fragment>
                    {values.order_receipt_details?.map(
                      (value: orderReceptUpload, index: number) => {
                        const field = `${DETAIL_NAME}[${index}]`
                        const isDisabled =
                          showInReportScreen ||
                          value?.withdrawal_flag ||
                          value?.id
                            ? true
                            : false
                        const isShowWithDrawButton =
                          !showInReportScreen &&
                          (value?.file || value?.url) &&
                          !value?.withdrawal_flag &&
                          value?.id
                            ? true
                            : false
                        return (
                          <Card
                            key={value?.id || index}
                            sx={{
                              ...cardStyles,
                              bgcolor: value?.withdrawal_flag
                                ? '#D3D3D3'
                                : '#d7e2ea',
                            }}
                          >
                            <Grid container spacing={2} sx={gridStyles}>
                              <AppTextField
                                xs={2.5}
                                name={`${field}.amount`}
                                value={value?.amount}
                                placeholder={t(
                                  'uploadRecept.orderReceipt.amount'
                                )}
                                label={t('uploadRecept.orderReceipt.amount')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                                disabled={isDisabled}
                              />
                              <CompanySelect
                                label={t('uploadRecept.orderReceipt.company')}
                                value={value?.company}
                                field={`${field}.company`}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                isDisabled={isDisabled}
                                setQuery={setQuery}
                              />
                              <AppDatePicker
                                id={t('uploadRecept.orderReceipt.issueDate')}
                                xs={3}
                                value={value?.issue_date}
                                name={`${field}.issue_date`}
                                label={t('uploadRecept.orderReceipt.issueDate')}
                                onChange={(value: any) => {
                                  setFieldValue(
                                    `${field}.issue_date`,
                                    dateFormatter(value)
                                  )
                                }}
                                variant="outlined"
                                sx={datePickerStyles}
                                disabled={isDisabled}
                              />
                            </Grid>

                            <FileUploadAndWithdrawBtn
                              edit={edit}
                              value={value}
                              index={index}
                              field={field}
                              allowedTypes={allowedTypes}
                              setFieldValue={setFieldValue}
                              disableFileDropZone={isDisabled}
                              uploadScreenInPlaceOfReport={
                                showInReportScreen ? true : false
                              }
                              showFileDownloadIcon={
                                isDisabled || value?.id ? true : false
                              }
                              showWithdrawButton={isShowWithDrawButton}
                              handleWithdrawalSubmit={async () => {
                                setIsWithdraw(true)
                                await submitForm()
                              }}
                            />
                          </Card>
                        )
                      }
                    )}
                  </React.Fragment>
                )}
              />

              <FocusElementOnInvalidValidation />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default OrderRecept
