import { saveAs } from 'file-saver'

import { generateQueryString } from '../../../Utils/AppFunction'
import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const issueQuotationURL = '/quotation'
const projectListModURL = '/case'
const purchaseOrdUpload = '/purchase-order'
const orderReceiptURL = '/order-receipt'
const purchaseOrderPlacementReceipt = '/order-receipt-placement'
const purchaseOrdReceiptUpload = '/usermade-order-receipt'
const purchaseDeliveryNoteApprove = '/delivery-note'
const certificateURL = '/certificate'
const artifactURL = '/artifact'
const certificateCompleteURL = '/case'
const deleteReferenceFile = '/reference-file'
const deleteRequestFile = '/request-file'
const certificateUploadModUrl = '/certificate-upload'
const completeCertificateUrl = '/complete-certificate'
const quotationDetailDraftUrl = '/quotation-detail-draft'
const projectWorkFlowHistoryUrl = '/case-history'

export const getProjectList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${projectListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
//
export const getProjectListDetail = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  return axiosInstance.get(
    `${projectListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      filterData ? `&${generateQueryString(filterData)}` : ''
    }${
      filterData?.work_in_progress
        ? `&work_in_progress=${filterData?.work_in_progress}`
        : ''
    }`
  )
}
export const deleteProject = (id: number, version: string | number) => {
  return axiosInstance.delete(`${projectListModURL}/${id}?version=${version}`)
}
//get joblist by id
export const getProjectListById = (id: number) => {
  return axiosInstance.get(`${projectListModURL}/${id}`)
}

//get quotation Detail Draft by id
export const getQuotationDetailDraftById = (id: number) => {
  return axiosInstance.get(`${quotationDetailDraftUrl}?case=${id}`)
}

//get quotation by id
export const getQuotationById = (id: number) => {
  return axiosInstance.get(`${issueQuotationURL}/${id}`)
}

//create quotation
export const createQuotation = (body: string | FormData) => {
  return axiosInstance.post(`${issueQuotationURL}`, body)
}

//update quotation
export const updateQuotation = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${issueQuotationURL}/${id}`, body)
}

//autoCalculate
export const autoCalculate = (body: FormData) => {
  return axiosInstance.post(`${'/auto-calculate'}`, body)
}

export const createProjectDetail = (body: string | FormData) => {
  return axiosInstance.post(`${projectListModURL}`, body)
}

export const getProjectDetailById = (id: number) => {
  return axiosInstance.get(`${projectListModURL}/${id}`)
}

export const updateProjectDetail = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${projectListModURL}/${id}`, body)
}

//get case purchase order by id
export const getPurchaseOrderById = (id: number) => {
  return axiosInstance.get(`${purchaseOrdUpload}/purchase-orders?case_id=${id}`)
}
//update purchase order by if
export const updatePurchaseOrder = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${purchaseOrdUpload}/${id}`, body)
}
//--------------------------------------------------------------

//purchase OrderReceipt by id
export const getOrderReceiptById = (id: number) => {
  return axiosInstance.get(`${orderReceiptURL}/${id}`)
}

//create job task purchase order
export const createOrderReceipt = (body: string | FormData) => {
  return axiosInstance.post(`${orderReceiptURL}`, body)
}

//update job task purchase order
export const updateOrderReceipt = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${orderReceiptURL}/${id}`, body)
}
export const updatePurchaseOrderPlacement = (body: string | FormData) => {
  // console.log(body, 'bodybody')
  return axiosInstance.post(`${purchaseOrderPlacementReceipt}`, body)
}

//
export const updatePurchaseOrderReceipt = (
  id: number,
  body: string | FormData
) => {
  return axiosInstance.patch(`${purchaseOrdReceiptUpload}/${id}`, body)
}

//
export const getPurchaseOrderReceiptById = (id: number) => {
  return axiosInstance.get(
    `${purchaseOrdReceiptUpload}/usermade-order-receipts?order_receipt_id=${id}`
  )
}

export const getSinglePurchaseOrderReceiptById = (id: number) => {
  return axiosInstance.get(`${purchaseOrdReceiptUpload}/${id}`)
}

//
export const createPurchaseOrderReceipt = (body: string | FormData) => {
  return axiosInstance.post(`${purchaseOrdReceiptUpload}`, body)
}
// certificate by id
export const getCertificateById = (id: number) => {
  return axiosInstance.get(`${certificateUploadModUrl}/${id}`)
}

export const completeCertificate = (body: object) => {
  return axiosInstance.post(completeCertificateUrl, body)
}

// /api/v1/usermade-order-receipt/{id}

export const getDeliveryNoteApproveById = (id: number) => {
  return axiosInstance.get(`${purchaseDeliveryNoteApprove}/${id}`)
}

export const createDeliveryNoteApprove = (body: string | FormData) => {
  return axiosInstance.post(`${purchaseDeliveryNoteApprove}`, body)
}
export const updateDeliveryNoteApprove = (
  id: number,
  body: string | FormData
) => {
  console.log(id, 'id')
  return axiosInstance.patch(`${purchaseDeliveryNoteApprove}/${id}`, body)
}
//update Certificate
export const updateCertificate = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${certificateUploadModUrl}/${id}`, body)
}

//  create artifact
export const createArtifact = (body: string | FormData) => {
  return axiosInstance.post(`${artifactURL}`, body)
}

// get artifact by id
export const getArtifactById = (id: number) => {
  return axiosInstance.get(`${artifactURL}/${id}`)
}

//update artifact
export const updateArtifact = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${artifactURL}/${id}`, body)
}

export const deleteReferenceFileId = (id: number) => {
  return axiosInstance.delete(`${deleteReferenceFile}/${id}`)
}

export const deleteRequestFileId = (id: number) => {
  return axiosInstance.delete(`${deleteRequestFile}/${id}`)
}

export const createPurchaseOrderUpload = (body: string | FormData) => {
  return axiosInstance.post(`${purchaseOrdUpload}`, body)
}

export const updateInspectionCertificate = (
  id: number,
  body: string | FormData
) => {
  return axiosInstance.patch(`${certificateUploadModUrl}/${id}`, body)
}

export const createInspectionCertificateUpload = (body: string | FormData) => {
  return axiosInstance.post(`${certificateUploadModUrl}`, body)
}

//
export const getInspectionCertificateUploadById = (id: number) => {
  return axiosInstance.get(
    `${certificateUploadModUrl}/uploaded-certificates?case_id=${id}`
  )
}

export const createProjectIssueInvoice = (body: string | FormData) => {
  return axiosInstance.post(`/case-invoice`, body)
}
//update job detail Invoice
export const updateProjectIssueInvoice = (
  id: number,
  body: string | FormData
) => {
  return axiosInstance.patch(`/case-invoice/${id}`, body)
}
//get invoice by job detail id
export const getInvoiceById = (id: number) => {
  return axiosInstance.get(`/case-invoice/${id}`)
}

export const sendJobCreateAndMail = (body: string | FormData) => {
  return axiosInstance.post(`/create-job-and-send-mail`, body)
}

export const sendOrderReceiptAcceptance = (body: string | FormData) => {
  return axiosInstance.post(`/order-receipt-acceptance`, body)
}

export const sendDeliveryNoteAcceptance = (body: string | FormData) => {
  return axiosInstance.post(`/delivery-note-acceptance`, body)
}

//update delivery withdrawal
export const updateDeliveryWithdrawal = (id: number) => {
  return axiosInstance.post(`/delivery-note/${id}/withdraw-delivery-note`)
}

//get project list sales export
// export const getProjectListSalesExport = () => {
//   const data = {}
//   return axiosInstance.get(`${projectListModURL}`, {
//     headers: {
//       'Content-Type': 'text/csv',
//       Accept: 'text/csv',
//     },
//     data,
//   })
// }

export const getProjectListSalesExport = async (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  console.log('generateQueryString(filterData)', filterData)
  try {
    const { data, headers } = await axiosInstance.get(
      `${projectListModURL}?offset=${
        rowsPerPage * page
      }&limit=${rowsPerPage}${`${
        filterData ? `&${generateQueryString(filterData)}` : ''
      }`}`,
      {
        paramsSerializer: {
          indexes: null,
        },
        headers: {
          Accept: 'text/csv',
        },
        responseType: 'blob',
      }
    )
    const mineType = headers['content-type']
    const dispostion = headers['content-disposition']
    const fileName = decodeURI(
      dispostion.split(';')[1].split('=')[1].replace('"', '').replace('"', '')
    )
    const blob = new Blob([data], { type: mineType })
    saveAs(blob, fileName)
  } catch (error) {
    console.log(error)
  }
}

//get case workflow history
export const getProjectWorkFlowHistory = (id: number) => {
  return axiosInstance.get(`${projectWorkFlowHistoryUrl}/${id}`)
}
