import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable, from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertInfoAction,
  alertSuccessAction,
} from '../../../CommonAppRedux/commonAppSlice'
import {
  extractAutoCalculateErrorMessage,
  extractErrorMessage,
} from '../../../Utils/AppFunction'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import { getOrderListDetailRequest } from '../../OrderList/Redux/orderListSlice'
import {
  createQuotation,
  getProjectList,
  getQuotationById,
  updateQuotation,
  createProjectDetail,
  getQuotationDetailDraftById,
  getProjectDetailById,
  getProjectListDetail,
  updateProjectDetail,
  getPurchaseOrderById,
  updatePurchaseOrder,
  getOrderReceiptById,
  createOrderReceipt,
  updateOrderReceipt,
  updatePurchaseOrderPlacement,
  updatePurchaseOrderReceipt,
  getPurchaseOrderReceiptById,
  createPurchaseOrderReceipt,
  getDeliveryNoteApproveById,
  createDeliveryNoteApprove,
  updateDeliveryNoteApprove,
  createArtifact,
  getArtifactById,
  updateCertificate,
  getCertificateById,
  updateArtifact,
  deleteReferenceFileId,
  deleteRequestFileId,
  createPurchaseOrderUpload,
  updateInspectionCertificate,
  createInspectionCertificateUpload,
  getInspectionCertificateUploadById,
  createProjectIssueInvoice,
  getInvoiceById,
  updateProjectIssueInvoice,
  sendJobCreateAndMail,
  sendOrderReceiptAcceptance,
  sendDeliveryNoteAcceptance,
  deleteProject,
  autoCalculate,
  updateDeliveryWithdrawal,
  completeCertificate,
  getProjectListSalesExport,
  getProjectWorkFlowHistory,
  getSinglePurchaseOrderReceiptById,
} from './api'
import {
  loadingProjectList,
  getProjectListSuccess,
  getProjectListFail,
  getProjectListRequest,
  getProjectListDetailSuccess,
  getProjectListDetailFail,
  getProjectDetailListNextRequest,
  getProjectDetailListPreviousRequest,
  getQuotationDetailDraftByIdRequest,
  getQuotationByIdRequest,
  getQuotationByIdSuccess,
  getQuotationStatusRequest,
  getQuotationStatusFinished,
  getQuotationByIdFail,
  createQuotationRequest,
  createQuotationSuccess,
  createQuotationFail,
  updateQuotationRequest,
  clearQuotation,
  updateQuotationSuccess,
  updateQuotationFail,
  createProjectDetailsRequest,
  createProjectDetailsSuccess,
  createProjectDetailsFail,
  getProjectDetailByIdRequest,
  getCaseDetailByIdRequest,
  getProjectDetailByIdSuccess,
  getProjectDetailByIdFail,
  updateProjectDetailsFail,
  updateProjectDetailsSuccess,
  updateProjectDetailsRequest,
  getProjectListDetailRequest,
  updatePurchaseOrderRequest,
  updatePurchaseOrderSuccess,
  updatePurchaseOrderFail,
  getPurchaseOrderByIdRequest,
  getPurchaseOrderByIdSuccess,
  getPurchaseOrderByIdFail,
  getOrderReceiptByIdRequest,
  getOrderReceiptByIdSuccess,
  getOrderReceiptByIdFail,
  createOrderReceiptRequest,
  createOrderReceiptSuccess,
  createOrderReceiptFail,
  updateOrderReceiptRequest,
  updateOrderReceiptSuccess,
  updateOrderReceiptFail,
  updatePurchaseOrderPlacementRequest,
  updatePurchaseOrderPlacementSuccess,
  updatePurchaseOrderPlacementFail,
  updatePurchaseOrderReceiptFail,
  updatePurchaseOrderReceiptSuccess,
  updatePurchaseOrderReceiptRequest,
  getPurchaseOrderReceiptByIdRequest,
  getPurchaseOrderReceiptByIdSuccess,
  getPurchaseOrderReceiptByIdFail,
  createPurchaseOrderReceiptSuccess,
  createPurchaseOrderReceiptFail,
  createPurchaseOrderReceiptRequest,
  getProjectDeliveryNoteApproveByIdRequest,
  getProjectDeliveryNoteApproveByIdSuccess,
  getProjectDeliveryNoteApproveByIdFail,
  createProjectDeliveryNoteApproveRequest,
  createProjectDeliveryNoteApproveSuccess,
  createProjectDeliveryNoteApproveFail,
  updateProjectDeliveryNoteApproveRequest,
  updateProjectDeliveryNoteApproveSuccess,
  updateProjectDeliveryNoteApproveFail,
  clearProjectDeliveryNoteApprove,
  createArtifactSuccess,
  createArtifactFail,
  createArtifactRequest,
  getArtifactByIdSuccess,
  getArtifactByIdFail,
  getArtifactByIdRequest,
  updateCertificateSuccess,
  updateCertificateFail,
  updateCertificateRequest,
  getCertificateByIdFail,
  getCertificateByIdSuccess,
  getCertificateByIdRequest,
  updateArtifactFail,
  updateArtifactSuccess,
  updateArtifactRequest,
  completeCertificateRequest,
  completeCertificateFinish,
  deleteReferenceFileByIdSuccess,
  deleteReferenceFileByIdFail,
  deleteReferenceFileByIdRequest,
  deleteRequestFileByIdRequest,
  deleteRequestFileByIdSuccess,
  deleteRequestFileByIdFail,
  createPurchaseOrderUploadRequest,
  createPurchaseOrderUploadSuccess,
  createPurchaseOrderUploadFail,
  updateInspectionCertificateUploadSuccess,
  updateInspectionCertificateUploadFail,
  updateInspectionCertificateUploadRequest,
  createInspectionCertificateUploadSuccess,
  createInspectionCertificateUploadFail,
  createInspectionCertificateUploadRequest,
  getInspectionCertificateByIdRequest,
  getInspectionCertificateByIdSuccess,
  getInspectionCertificateByIdFail,
  createProjectIssueInvoiceRequest,
  createProjectIssueInvoiceSuccess,
  createProjectIssueInvoiceFail,
  getProjectDetailInvoiceByIdRequest,
  getProjectDetailInvoiceByIdSuccess,
  getProjectDetailInvoiceByIdFail,
  updateProjectIssueInvoiceFail,
  updateProjectIssueInvoiceSuccess,
  clearProjectDetailInvoice,
  updateProjectIssueInvoiceRequest,
  sendJobCreateAndMailSuccess,
  sendJobCreateAndMailFail,
  sendJobCreateAndMailRequest,
  sendOrderReceiptAcceptanceRequest,
  sendOrderReceiptAcceptanceSuccess,
  sendOrderReceiptAcceptanceFail,
  sendDeliveryNoteAcceptanceRequest,
  sendDeliveryNoteAcceptanceSuccess,
  sendDeliveryNoteAcceptanceFail,
  deleteProjectRequest,
  autoCalculateRequest,
  autoCalculateSuccess,
  autoCalculateFail,
  updateDeliveryWithdrawalRequest,
  updateDeliveryWithdrawalSuccess,
  updateDeliveryWithdrawalFail,
  deleteProjectSuccess,
  deleteProjectFail,
  clearDataProjectDetails,
  getQuotationDetailDraftByIdSuccess,
  getQuotationDetailDraftByIdFail,
  clearOrderReceipt,
  clearPurchaseOrderDetails,
  clearArtifact,
  clearInspectionCertificateUploadDetails,
  projectListSalesExportRequest,
  projectListSalesExportSuccess,
  projectListSalesExportFail,
  getProjectWorkFlowHistoryRequest,
  getProjectWorkFlowHistorySuccess,
  getProjectWorkFlowHistoryFail,
  clearHardResetProjectList,
  getInputTrackRecordStatusFinished,
  getInputTrackRecordStatusRequest,
  getDeliveryItemUploadStatusRequest,
  getDeliveryItemUploadStatusFinished,
  getPurchaseOrderUploadStatusRequest,
  getPurchaseOrderUploadStatusFinished,
  getCertificateUploadStatusRequest,
  getCertificateUploadStatusFinished,
  getCompleteCertificateStatusRequest,
  getCompleteCertificateStatusFinished,
  getDeliveryReportStatusRequest,
  getDeliveryReportStatusFinished,
  getOrderReceiptStatusRequest,
  getOrderReceiptStatusFinished,
  getUserMadeInvoiceStatusRequest,
  getUserMadeInvoiceStatusFinish,
  getCaseInvoiceStatusRequest,
  getCaseInvoiceStatusFinish,
  getSinglePurchaseOrderReceiptByIdRequest,
} from './projectListSlice'

const getProjectListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getProjectListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingProjectList())
      try {
        const response = await getProjectList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectListSuccess(action?.payload)
        : getProjectListFail()
    )
  )
}
//
const getProjectListDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getProjectListDetailRequest.match),
    switchMap(async (action) => {
      dispatch(loadingProjectList())
      try {
        const response = await getProjectListDetail(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectListDetailSuccess(action?.payload)
        : getProjectListDetailFail()
    )
  )
}
//
//
//get next
const getProjectDetailListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProjectDetailListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectListDetailSuccess(action?.payload)
        : getProjectListDetailFail()
    )
  )
//get previous
const getProjectDetailListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProjectDetailListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectListDetailSuccess(action?.payload)
        : getProjectListDetailFail()
    )
  )
//delete project
const deleteProjectEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(deleteProjectRequest.match),
    switchMap(async ({ payload: { id, version, navigate, is_staff } }) => {
      try {
        const response = await deleteProject(id, version)
        if (response) {
          dispatch(alertSuccessAction(t(messages.deleteSuccess)))
          if (!is_staff) {
            navigate('/project-list-client')
          } else {
            navigate('/project-list')
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload ? deleteProjectSuccess() : deleteProjectFail()
    )
  )
}
//
const getQuotationByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getQuotationByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getQuotationById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getQuotationByIdSuccess(action?.payload)
        : getQuotationByIdFail()
    )
  )
}
const getQuotationStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getQuotationStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const {
            id,
            case_report_relation: { quotation },
          } = response.data
          const caseId = id
          if (caseId && quotation) {
            const queryParams = `?quotation-id=${quotation}&case-id=${caseId}&edit=${true}`
            navigate(`/issue-quotation${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          } else {
            const queryParams = `?case-id=${caseId}`
            navigate(`/issue-quotation${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getQuotationStatusFinished())
  )
}
const getInputTrackRecordStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getInputTrackRecordStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const queryParams = response?.data?.costs[0]
            ? `?case-id=${response?.data?.id}&edit=${true}`
            : `?case-id=${response?.data?.id}`
          navigate(`/input-track-record${queryParams}`, {
            state: { path: location?.pathname + location?.search },
          })
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getInputTrackRecordStatusFinished())
  )
}
const getDeliveryItemUploadStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getDeliveryItemUploadStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const { id, artifact } = response.data
          const queryParams = artifact ? `?id=${id}&edit=${true}` : `?id=${id}`
          navigate(`/delivery-item-upload${queryParams}`, {
            state: { path: location?.pathname + location?.search },
          })
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getDeliveryItemUploadStatusFinished())
  )
}
const getPurchaseOrderUploadStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getPurchaseOrderUploadStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const { id, purchase_order_case } = response.data
          const purchaseOrderId = purchase_order_case?.id
          if (id) {
            const queryParams = purchaseOrderId
              ? `?case-id=${id}&edit=${true}`
              : `?case-id=${id}`
            await navigate(`/purchase-order-upload${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getPurchaseOrderUploadStatusFinished())
  )
}
const getCertificateUploadStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCertificateUploadStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const { id, case_report_relation } = response.data
          if (case_report_relation?.certificate) {
            const queryParams = `?case-id=${id}&edit=${true}`
            navigate(`/certificate-upload${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          } else if (response?.data?.job) {
            const queryParams = `?case-id=${id}`
            navigate(`/certificate-upload${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          } else {
            dispatch(alertInfoAction(t('customAlertMessage.getJobIdFirst')))
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getCertificateUploadStatusFinished())
  )
}
const getCompleteCertificateStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCompleteCertificateStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const { id, case_report_relation } = response.data
          if (id) {
            // navigate(`${`/certificate-complete-confirm/${id}`}`)
            const queryParams = `?case-id=${id}&certificate=${
              case_report_relation?.certificate ?? ''
            }`
            navigate(`/certificate-complete-confirm${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          } else {
            dispatch(alertErrorAction(t('customAlertMessage.caseIdNotFound')))
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getCompleteCertificateStatusFinished())
  )
}
const getDeliveryReportStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getDeliveryReportStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const { id, case_report_relation } = response.data
          if (case_report_relation?.delivery_note) {
            const deliveryNoteId = case_report_relation.delivery_note
            const queryParams = `?delivery-note-id=${deliveryNoteId}&case-id=${id}&edit=${true}`
            navigate(`/delivery-report-note-approve${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          } else {
            const queryParams = `?case-id=${id}`
            navigate(`/delivery-report-note-approve${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getDeliveryReportStatusFinished())
  )
}
const getOrderReceiptStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getOrderReceiptStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const { id, case_report_relation } = response.data
          const orderReceiptId = case_report_relation?.order_receipt
          const caseId = id
          if (orderReceiptId) {
            //if both exist then navigate to edit
            const queryParams = orderReceiptId
              ? `?order-receipt-id=${orderReceiptId}&case-id=${caseId}&edit=${true}`
              : `?case-id=${caseId}`
            navigate(`/issue-order-receipt${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          } else {
            const queryParams = `?case-id=${caseId}`
            navigate(`/issue-order-receipt${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getOrderReceiptStatusFinished())
  )
}
const getUserMadeInvoiceStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getUserMadeInvoiceStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getInvoiceById(id)
        const usermade_invoice = response?.data?.usermade_invoice?.id
        if (usermade_invoice) {
          const queryParams = `?invoice-id=${response?.data?.id}&edit=${true}`
          navigate(`/usermade-invoice-upload${queryParams}`, {
            state: { path: location?.pathname + location?.search },
          })
        } else {
          const queryParams = `?invoice-id=${response?.data?.id}`
          navigate(`/usermade-invoice-upload${queryParams}`, {
            state: { path: location?.pathname + location?.search },
          })
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getUserMadeInvoiceStatusFinish())
  )
}
const getCaseInvoiceStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCaseInvoiceStatusRequest.match),
    switchMap(async ({ payload: { id, navigate, location } }) => {
      try {
        const response = await getProjectDetailById(id)
        if (response) {
          const { id, case_report_relation } = response.data
          const invoiceId = case_report_relation?.invoice
          if (invoiceId) {
            const queryParams = `?invoice-id=${invoiceId}&case-id=${id}&edit=${true}`
            navigate(`/case-issue-invoice${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          } else {
            const queryParams = `?case-id=${id}`
            navigate(`/case-issue-invoice${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) => getCaseInvoiceStatusFinish())
  )
}
const getQuotationDetailDraftByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getQuotationDetailDraftByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getQuotationDetailDraftById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getQuotationDetailDraftByIdSuccess(action?.payload)
        : getQuotationDetailDraftByIdFail()
    )
  )
}

//create
const createProjectDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createProjectDetailsRequest.match),
    switchMap(async ({ payload: { values, nav, actions, previousUrl } }) => {
      try {
        const body = new FormData()
        for (const [key, value] of Object.entries(values)) {
          if (key === 'case_details') {
            //@ts-ignore
            value.forEach((jobDetail: any, index: number) => {
              for (const [detailKey, detailValue] of Object.entries(
                jobDetail
              )) {
                if (
                  detailKey === 'reference_files' ||
                  detailKey === 'request_files'
                ) {
                  //@ts-ignore
                  detailValue.forEach((file: any, fileIndex: number) => {
                    if (file?.url !== '') {
                      body.append(
                        `case_details[${index}]${detailKey}[${fileIndex}]url`,
                        String(file.url)
                      )
                    }
                    if (typeof file?.file !== 'string') {
                      body.append(
                        `case_details[${index}]${detailKey}[${fileIndex}]file`,
                        file.file
                      )
                    }
                  })
                } else if (detailKey === 'translation_languages') {
                  jobDetail?.translation_languages.forEach(
                    (language: any, languageIndex: number) => {
                      body.append(
                        `case_details[${index}]${detailKey}[${languageIndex}]id`,
                        language.id
                      )
                    }
                  )
                } else if (detailKey === 'auto_calculate_details') {
                  jobDetail?.auto_calculate_details.forEach(
                    (language: any, languageIndex: number) => {
                      body.append(
                        `case_details[${index}]${detailKey}[${languageIndex}]quantity`,
                        language.quantity
                      )
                      body.append(
                        `case_details[${index}]${detailKey}[${languageIndex}]unit_cost`,
                        language.unit_cost
                      )
                      body.append(
                        `case_details[${index}]${detailKey}[${languageIndex}]item_name`,
                        language.item_name
                      )
                      body.append(
                        `case_details[${index}]${detailKey}[${languageIndex}]amount`,
                        language.amount
                      )
                      body.append(
                        `case_details[${index}]${detailKey}[${languageIndex}]tax_amount`,
                        language.tax_amount
                      )
                    }
                  )
                } else {
                  body.append(
                    `case_details[${index}]${detailKey}`,
                    String(detailValue)
                  )
                }
              }
            })
          } else {
            body.append(key, String(value))
          }
        }
        const response = await createProjectDetail(body)
        if (response) {
          // actions.resetForm()
          dispatch(alertSuccessAction(t(messages.processSuccess)))
          dispatch(clearDataProjectDetails())
          const queryParams = `?id=${response?.data?.id}&edit=${true}`
          if (nav) {
            nav(`/create-agency-project${queryParams}`)
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createProjectDetailsSuccess(action?.payload)
        : createProjectDetailsFail()
    )
  )
}
//

// create Quotation
const createQuotationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createQuotationRequest.match),
    switchMap(async ({ payload: { values, nav, actions, previousUrl } }) => {
      try {
        const { case: caseId } = values
        const body = JSON.stringify(values)

        const response = await createQuotation(body)
        if (response) {
          // actions.resetForm()
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          // await nav(`${previousUrl}`)
          const quotationId = response?.data?.id
          const queryParams = `?quotation-id=${quotationId}&case-id=${caseId}&edit=${true}`
          nav(`/issue-quotation${queryParams}`)
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
      }
    }),
    map((action) =>
      action?.payload
        ? createQuotationSuccess(action?.payload)
        : createQuotationFail()
    )
  )
}

const updateProjectDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateProjectDetailsRequest.match),
    switchMap(
      async ({
        payload: { id, values, nav, actions, previousUrl, isSend },
      }) => {
        dispatch(loadingProjectList())
        try {
          const body = new FormData()
          for (const [key, value] of Object.entries(values)) {
            if (key === 'case_details') {
              //@ts-ignore
              value.forEach((jobDetail: any, index: number) => {
                for (const [detailKey, detailValue] of Object.entries(
                  jobDetail
                )) {
                  if (
                    (detailKey === 'reference_files' ||
                      detailKey === 'request_files') &&
                    detailValue
                  ) {
                    //@ts-ignore
                    detailValue.forEach((file: any, fileIndex: number) => {
                      if (file?.url !== '') {
                        body.append(
                          `case_details[${index}]${detailKey}[${fileIndex}]url`,
                          String(file.url)
                        )
                      }
                      if (typeof file?.file !== 'string') {
                        body.append(
                          `case_details[${index}]${detailKey}[${fileIndex}]file`,
                          file.file
                        )
                      }
                      if (file.id) {
                        body.append(
                          `case_details[${index}]${detailKey}[${fileIndex}]id`,
                          file.id
                        )
                      }

                      // else {
                      //   body.append(
                      //     `case_details[${index}]${detailKey}[${fileIndex}]file`,
                      //     file.file
                      //   )
                      // }
                      // else{
                      //   body.append(
                      //     `case_details[${index}]${detailKey}[${fileIndex}]file`,
                      //     file.file
                      //   )
                      // }
                    })
                  } else if (detailKey === 'translation_languages') {
                    jobDetail?.translation_languages.forEach(
                      (language: any, languageIndex: number) => {
                        body.append(
                          `case_details[${index}]${detailKey}[${languageIndex}]id`,
                          language.id
                        )
                      }
                    )
                  } else if (detailKey === 'auto_calculate_details') {
                    jobDetail?.auto_calculate_details.forEach(
                      (language: any, languageIndex: number) => {
                        body.append(
                          `case_details[${index}]${detailKey}[${languageIndex}]quantity`,
                          language.quantity
                        )
                        body.append(
                          `case_details[${index}]${detailKey}[${languageIndex}]unit_cost`,
                          language.unit_cost
                        )
                        body.append(
                          `case_details[${index}]${detailKey}[${languageIndex}]item_name`,
                          language.item_name
                        )
                        body.append(
                          `case_details[${index}]${detailKey}[${languageIndex}]amount`,
                          language.amount
                        )
                        body.append(
                          `case_details[${index}]${detailKey}[${languageIndex}]tax_amount`,
                          language.tax_amount
                        )
                      }
                    )
                  } else {
                    body.append(
                      `case_details[${index}]${detailKey}`,
                      String(detailValue)
                    )
                  }
                }
              })
            } else {
              body.append(key, String(value))
            }
          }
          const response = await updateProjectDetail(id, body)
          if (response) {
            if (actions) {
              // actions.resetForm()
            }
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            dispatch(clearDataProjectDetails())
            if (nav) {
              const queryParams = `?id=${id}&edit=${true}`
              nav(`/create-agency-project${queryParams}`)
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateProjectDetailsSuccess(action?.payload)
        : updateProjectDetailsFail()
    )
  )
}
//
const getCaseDetailByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCaseDetailByIdRequest.match),
    switchMap(async ({ payload: { id, navigate, is_staff } }) => {
      dispatch(loadingProjectList())
      try {
        const response = await getProjectDetailById(id)
        return { payload: response.data }
      } catch (e: any) {
        const PAGE_NOT_FOUND = 404
        if (e?.response?.status === PAGE_NOT_FOUND) {
          is_staff
            ? navigate('/project-list')
            : navigate('/project-list-client')
        } else {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectDetailByIdSuccess(action?.payload)
        : getProjectDetailByIdFail()
    )
  )
}

//
const getProjectDetailByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getProjectDetailByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingProjectList())
      try {
        const response = await getProjectDetailById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectDetailByIdSuccess(action?.payload)
        : getProjectDetailByIdFail()
    )
  )
}

//update Quotation
const updateQuotationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateQuotationRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, actions, previousUrl } }) => {
        try {
          const body = JSON.stringify(values)
          const response = await updateQuotation(id, body)
          if (response) {
            // actions.resetForm()

            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            dispatch(clearQuotation())
            dispatch(getQuotationByIdRequest(id))
            const queryParams = `?quotation-id=${id}&case-id=${
              values?.case
            }&edit=${true}`
            nav(`/issue-quotation${queryParams}`)
            // await nav(`${previousUrl}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateQuotationSuccess(action?.payload)
        : updateQuotationFail()
    )
  )
}

const getPurchaseOrderByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getPurchaseOrderByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingProjectList())
      try {
        const response = await getPurchaseOrderById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseOrderByIdSuccess(action?.payload)
        : getPurchaseOrderByIdFail()
    )
  )
}
//
const getPurchaseOrderReceiptByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getPurchaseOrderReceiptByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingProjectList())
      try {
        const response = await getPurchaseOrderReceiptById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseOrderReceiptByIdSuccess(action?.payload)
        : getPurchaseOrderReceiptByIdFail()
    )
  )
}

const getSinglePurchaseOrderReceiptByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getSinglePurchaseOrderReceiptByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingProjectList())
      try {
        const response = await getSinglePurchaseOrderReceiptById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseOrderReceiptByIdSuccess(action?.payload)
        : getPurchaseOrderReceiptByIdFail()
    )
  )
}

//update purchase order
const updatePurchaseOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updatePurchaseOrderRequest.match),
    switchMap(
      async ({ payload: { id, values, actions, previousUrl, nav } }) => {
        const { file, ...restValues } = values
        try {
          const body = new FormData()
          for (let [key, value] of Object.entries(restValues)) {
            //@ts-ignore
            body.append(`${key}`, value)
          }
          if (file === null) {
            body.append('file', '')
          } else if (typeof file !== 'string') {
            body.append('file', file)
          }
          const response = await updatePurchaseOrder(id, body)
          if (response) {
            if (actions) {
              actions.resetForm()
            }

            dispatch(clearHardResetProjectList())
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            // dispatch(getPurchaseOrderByIdRequest(id))
            // await nav(`${previousUrl}`)
            const queryParams = `?case-id=${values?.case}&edit=${true}`
            await nav(`/purchase-order-upload${queryParams}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updatePurchaseOrderSuccess(action?.payload)
        : updatePurchaseOrderFail()
    )
  )
}
//create purchase order
const createPurchaseOrderUploadEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createPurchaseOrderUploadRequest.match),
    switchMap(
      async ({ payload: { id, values, actions, previousUrl, nav } }) => {
        const { file, ...restValues } = values
        try {
          const body = new FormData()
          for (let [key, value] of Object.entries(restValues)) {
            //@ts-ignore
            body.append(`${key}`, value)
          }
          if (file) {
            body.append('file', file)
          } else {
            body.append('file', '')
          }
          const response = await createPurchaseOrderUpload(body)
          if (response) {
            // actions.resetForm()
            dispatch(alertSuccessAction(t(messages.createSuccess)))
            // dispatch(getPurchaseOrderByIdRequest(response?.data?.id))
            const queryParams = `?case-id=${values?.case}&edit=${true}`
            nav(`/purchase-order-upload${queryParams}`)
            // await nav(`${previousUrl}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? createPurchaseOrderUploadSuccess(action?.payload)
        : createPurchaseOrderUploadFail()
    )
  )
}
//
const updatePurchaseOrderReceiptEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updatePurchaseOrderReceiptRequest.match),

    switchMap(
      async ({
        payload: { id, orderReceiptId, values, actions, previousUrl, nav },
      }) => {
        const { file, ...updateValues } = values
        try {
          const body = new FormData()
          for (let [key, value] of Object.entries(updateValues)) {
            //@ts-ignore
            body.append(`${key}`, value)
          }
          if (file) {
            body.append(`file`, file)
          }
          const response = await updatePurchaseOrderReceipt(id, body)
          if (response) {
            if (actions) {
              actions.resetForm()
            }
            dispatch(clearPurchaseOrderDetails())
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            const queryParams = `?purchase-order-receipt-id=${orderReceiptId}&edit=${true}`
            nav(`/order-receipt-upload${queryParams}`)
            // await nav(`${previousUrl}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updatePurchaseOrderReceiptSuccess(action?.payload)
        : updatePurchaseOrderReceiptFail()
    )
  )
}
const updatePurchaseOrderPlacementEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updatePurchaseOrderPlacementRequest.match),
    switchMap(async (action) => {
      try {
        const response = await updatePurchaseOrderPlacement(action.payload)
        if (response) {
          dispatch(alertSuccessAction(t(messages.updateSuccess)))
          if (action?.payload?.orderReceiptList) {
            dispatch(
              getOrderListDetailRequest({
                page: defaultPage,
                rowsPerPage: defaultRowsPerPage,
              })
            )
          } else if (action?.payload?.createAgencyProjectList) {
            dispatch(getProjectDetailByIdRequest(action.payload?.case))
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? updatePurchaseOrderPlacementSuccess(action?.payload)
        : updatePurchaseOrderPlacementFail()
    )
  )
}

const createPurchaseOrderPlacementEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createPurchaseOrderReceiptRequest.match),
    switchMap(
      async ({ payload: { orderReceiptId, values, nav, previousUrl } }) => {
        try {
          const { updateValue } = values
          const { file } = updateValue
          const body = new FormData()
          for (let [key, value] of Object.entries(updateValue)) {
            //@ts-ignore
            body.append(`${key}`, value)
          }
          if (file) {
            body.append(`file`, file)
          }
          const response = await createPurchaseOrderReceipt(body)
          if (response) {
            dispatch(alertSuccessAction(t(messages.createSuccess)))
            const queryParams = `?purchase-order-receipt-id=${orderReceiptId}&edit=${true}`
            nav(`/order-receipt-upload${queryParams}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? createPurchaseOrderReceiptSuccess(action?.payload)
        : createPurchaseOrderReceiptFail()
    )
  )
}

// get  OrderReceipt
const getOrderReceiptByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getOrderReceiptByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getOrderReceiptById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getOrderReceiptByIdSuccess(action?.payload)
        : getOrderReceiptByIdFail()
    )
  )
}

// create OrderReceipt
const createOrderReceiptEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createOrderReceiptRequest.match),
    switchMap(async ({ payload: { values, nav, actions, previousUrl } }) => {
      try {
        console.log(values, 'values')
        const body = JSON.stringify(values)
        const response = await createOrderReceipt(body)
        if (response) {
          if (actions) {
            actions.resetForm()
          }
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          const orderReceiptId = response?.data?.id
          const queryParams = `?order-receipt-id=${orderReceiptId}&case-id=${
            values?.case
          }&edit=${true}`
          nav(`/issue-order-receipt${queryParams}`)
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createOrderReceiptSuccess(action?.payload)
        : createOrderReceiptFail()
    )
  )
}

//update OrderReceipt
const updateOrderReceiptEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateOrderReceiptRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, actions, previousUrl } }) => {
        try {
          console.log(values, 'values')
          const body = JSON.stringify(values)
          const response = await updateOrderReceipt(id, body)
          if (response) {
            actions.resetForm()
            dispatch(clearOrderReceipt())
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            dispatch(getOrderReceiptByIdRequest(id))
            const queryParams = `?order-receipt-id=${id}&case-id=${
              values?.case
            }&edit=${true}`
            nav(`/issue-order-receipt${queryParams}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateOrderReceiptSuccess(action?.payload)
        : updateOrderReceiptFail()
    )
  )
}

//

//
const getDeliveryNoteApproveByProjectDetailIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getProjectDeliveryNoteApproveByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getDeliveryNoteApproveById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectDeliveryNoteApproveByIdSuccess(action?.payload)
        : getProjectDeliveryNoteApproveByIdFail()
    )
  )
}
const createProjectDeliveryNoteApproveEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createProjectDeliveryNoteApproveRequest.match),
    switchMap(async ({ payload: { values, nav, previousUrl, orderList } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await createDeliveryNoteApprove(body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          if (!orderList && nav) {
            const deliveryNoteId = response?.data?.id
            const queryParams = `?delivery-note-id=${deliveryNoteId}&case-id=${
              values?.case
            }&edit=${true}`
            if (nav) {
              nav(`/delivery-report-note-approve${queryParams}`)
            }
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createProjectDeliveryNoteApproveSuccess(action?.payload)
        : createProjectDeliveryNoteApproveFail()
    )
  )
}

const updateProjectDeliveryNoteApproveEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateProjectDeliveryNoteApproveRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, previousUrl, orderList } }) => {
        try {
          const body = JSON.stringify(values)
          const response = await updateDeliveryNoteApprove(id, body)
          if (response) {
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            dispatch(clearProjectDeliveryNoteApprove())
            await dispatch(getProjectDeliveryNoteApproveByIdRequest(id))

            // if (!orderList) {
            const queryParams = `?delivery-note-id=${id}&case-id=${
              values?.case
            }&edit=${true}`
            if (nav) {
              await nav(`/delivery-report-note-approve${queryParams}`)
            }
            // }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateProjectDeliveryNoteApproveSuccess(action?.payload)
        : updateProjectDeliveryNoteApproveFail()
    )
  )
}
//

const getCertificateByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCertificateByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getCertificateById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCertificateByIdSuccess(action?.payload)
        : getCertificateByIdFail()
    )
  )
}

//update OrderReceipt
const updateCertificateEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateCertificateRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, actions, previousUrl } }) => {
        try {
          const body = JSON.stringify(values)
          const response = await updateCertificate(id, body)
          if (response) {
            actions.resetForm()
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            await nav(`${previousUrl}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateCertificateSuccess(action?.payload)
        : updateCertificateFail()
    )
  )
}

// get  Artifact
const getArtifactByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getArtifactByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getArtifactById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getArtifactByIdSuccess(action?.payload)
        : getArtifactByIdFail()
    )
  )
}

// create OrderReceipt
const createArtifactEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createArtifactRequest.match),
    switchMap(async ({ payload: { values, nav, actions, previousUrl } }) => {
      try {
        const { auto_issue, send_only, file, ...restValues } = values
        const body = new FormData()
        for (let [key, value] of Object.entries(restValues)) {
          //@ts-ignore
          body.append(`${key}`, value)
        }
        if (file) {
          body.append(`file`, file)
        } else {
          body.append(`file`, '')
        }
        if (auto_issue) {
          body.append(`auto_issue`, auto_issue)
        }
        if (send_only) {
          body.append(`send_only`, send_only)
        }
        const response = await createArtifact(body)
        if (response) {
          actions.resetForm()
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          const queryParams = `?id=${values?.case}&edit=${true}`

          if (nav) {
            nav(`/delivery-item-upload${queryParams}`)
            dispatch(getArtifactByIdRequest(response?.data?.id))
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createArtifactSuccess(action?.payload)
        : createArtifactFail()
    )
  )
}

//update Artifact
const updateArtifactEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateArtifactRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, actions, previousUrl } }) => {
        try {
          const { file, ...restValues } = values
          const body = new FormData()
          for (let [key, value] of Object.entries(restValues)) {
            //@ts-ignore
            body.append(`${key}`, value)
          }
          if (file) {
            body.append(`file`, file)
          } else {
            body.append(`file`, '')
          }
          const response = await updateArtifact(id, body)
          if (response) {
            if (actions) {
              actions.resetForm()
            }
            dispatch(clearArtifact())
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            const queryParams = `?id=${values?.case}&edit=${true}`
            if (nav) {
              nav(`/delivery-item-upload${queryParams}`)
              dispatch(getArtifactByIdRequest(id))
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateArtifactSuccess(action?.payload)
        : updateArtifactFail()
    )
  )
}

// create CertificateComplete
const completeCertificateEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(completeCertificateRequest.match),
    switchMap(async ({ payload: { values, nav, actions, previousUrl } }) => {
      try {
        const response = await completeCertificate(values)
        if (response) {
          actions.resetForm()
          dispatch(clearDataProjectDetails())
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          await nav(`${previousUrl}`)
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map(() => completeCertificateFinish())
  )
}

// deleteReferenceFileId
const deleteReferenceFileIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(deleteReferenceFileByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await deleteReferenceFileId(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? deleteReferenceFileByIdSuccess()
        : deleteReferenceFileByIdFail()
    )
  )
}
//
const deleteRequestFileIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(deleteRequestFileByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await deleteRequestFileId(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? deleteRequestFileByIdSuccess()
        : deleteRequestFileByIdFail()
    )
  )
}
//
const updateInspectionCertificateEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateInspectionCertificateUploadRequest.match),
    switchMap(
      async ({ payload: { id, values, actions, previousUrl, navigate } }) => {
        const { file, amount, ...restValues } = values
        try {
          const body = new FormData()
          for (let [key, value] of Object.entries(restValues)) {
            //@ts-ignore
            body.append(`${key}`, value)
          }
          if (file === null) {
            body.append('file', '')
          } else if (typeof file !== 'string') {
            body.append('file', file)
          }
          body.append('amount', amount ? amount : 0)
          const response = await updateInspectionCertificate(id, body)
          if (response) {
            dispatch(clearInspectionCertificateUploadDetails())
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            if (navigate) {
              const queryParams = `?case-id=${values?.case}&edit=${true}`
              navigate(`/certificate-upload${queryParams}`)
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateInspectionCertificateUploadSuccess(action?.payload)
        : updateInspectionCertificateUploadFail()
    )
  )
}
//create Quotation
const createInspectionCertificateUploadEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createInspectionCertificateUploadRequest.match),
    switchMap(
      async ({
        payload: { values, actions, caseId, navigate, previousUrl },
      }) => {
        const { file, amount, ...restValues } = values
        try {
          const body = new FormData()
          for (let [key, value] of Object.entries(restValues)) {
            //@ts-ignore
            body.append(`${key}`, value)
          }
          if (file) {
            body.append('file', file)
          } else {
            body.append('file', '')
          }
          body.append('amount', amount ? amount : 0)
          const response = await createInspectionCertificateUpload(body)
          if (response) {
            dispatch(alertSuccessAction(t(messages.createSuccess)))
            if (navigate) {
              // await navigate(`${previousUrl}`)
              const queryParams = `?case-id=${caseId}&edit=${true}`
              navigate(`/certificate-upload${queryParams}`)
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? createInspectionCertificateUploadSuccess(action?.payload)
        : createInspectionCertificateUploadFail()
    )
  )
}

//
const getInspectionCertificateUploadByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getInspectionCertificateByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getInspectionCertificateUploadById(
          action.payload
        )
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getInspectionCertificateByIdSuccess(action?.payload)
        : getInspectionCertificateByIdFail()
    )
  )
}
//
const createProjectIssueInvoiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createProjectIssueInvoiceRequest.match),
    switchMap(async ({ payload: { values, nav, previousUrl } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await createProjectIssueInvoice(body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          const invoiceId = response?.data?.id
          const queryParams = `?invoice-id=${invoiceId}&case-id=${
            values?.case
          }&edit=${true}`
          nav(`/case-issue-invoice${queryParams}`)
          dispatch(clearProjectDetailInvoice())
          dispatch(getProjectDetailInvoiceByIdRequest(response?.data?.id))
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createProjectIssueInvoiceSuccess(action?.payload)
        : createProjectIssueInvoiceFail()
    )
  )
}
//get invoice by job detail  id epic
const getInvoiceByProjectDetailIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getProjectDetailInvoiceByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getInvoiceById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectDetailInvoiceByIdSuccess(action?.payload)
        : getProjectDetailInvoiceByIdFail()
    )
  )
}
const updateProjectIssueInvoiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateProjectIssueInvoiceRequest.match),
    switchMap(async ({ payload: { id, values, nav, previousUrl } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await updateProjectIssueInvoice(id, body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.updateSuccess)))
          dispatch(getProjectDetailInvoiceByIdRequest(id))
          dispatch(clearProjectDetailInvoice())
          const queryParams = `?invoice-id=${id}&case-id=${
            values?.case
          }&edit=${true}`
          nav(`/case-issue-invoice${queryParams}`)
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? updateProjectIssueInvoiceSuccess(action?.payload)
        : updateProjectIssueInvoiceFail()
    )
  )
}
//
const sendJobCreateAndMailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(sendJobCreateAndMailRequest.match),
    switchMap(async ({ payload: { values, nav, caseId } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await sendJobCreateAndMail(body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.sendSuccess)))
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? sendJobCreateAndMailSuccess(action?.payload)
        : sendJobCreateAndMailFail()
    )
  )
}
const sendOrderReceiptAcceptanceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(sendOrderReceiptAcceptanceRequest.match),
    switchMap(async ({ payload: { values } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await sendOrderReceiptAcceptance(body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.sendAcceptanceSuccess)))
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? sendOrderReceiptAcceptanceSuccess(action?.payload)
        : sendOrderReceiptAcceptanceFail()
    )
  )
}

const sendDeliveryNoteAcceptanceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(sendDeliveryNoteAcceptanceRequest.match),
    switchMap(async ({ payload: { values } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await sendDeliveryNoteAcceptance(body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.sendAcceptanceSuccess)))
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? sendDeliveryNoteAcceptanceSuccess(action?.payload)
        : sendDeliveryNoteAcceptanceFail()
    )
  )
}

const autoCalculateEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(autoCalculateRequest.match),
    mergeMap(({ payload: { data, index } }) =>
      from(autoCalculate(data)).pipe(
        map((res) => autoCalculateSuccess({ data: res?.data?.data, index })),
        switchMap((successAction) => {
          dispatch(successAction)
          return of(
            successAction,
            alertSuccessAction(t(messages?.createSuccess))
          )
        }),
        catchError((e) =>
          of(
            autoCalculateFail(),
            dispatch(alertErrorAction(extractAutoCalculateErrorMessage(e)))
          )
        )
      )
    )
  )
}

//update Delivery withdrawal flag
const updateDeliveryWithdrawalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateDeliveryWithdrawalRequest.match),
    switchMap(async ({ payload }) => {
      try {
        const response = await updateDeliveryWithdrawal(payload)
        if (response) {
          dispatch(alertSuccessAction(t(messages.updateSuccess)))
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? updateDeliveryWithdrawalSuccess()
        : updateDeliveryWithdrawalFail()
    )
  )
}

//project list sales export
const projectListSalesExportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(projectListSalesExportRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getProjectListSalesExport(action.payload)
        dispatch(alertSuccessAction(t(messages.downloadSuccess)))
        return { payload: response }
      } catch (e) {
        dispatch(alertErrorAction(t(messages.failedToExport)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? projectListSalesExportSuccess()
        : projectListSalesExportFail()
    )
  )
}

//project workflow history
const getProjectWorkFlowHistoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getProjectWorkFlowHistoryRequest.match),
    switchMap(async ({ payload: { id } }) => {
      try {
        const response = await getProjectWorkFlowHistory(id)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectWorkFlowHistorySuccess(action?.payload)
        : getProjectWorkFlowHistoryFail()
    )
  )
}

export const projectListEpics = combineEpics(
  getCaseDetailByIdEpic,
  getProjectListEpic,
  getProjectDetailListNext,
  deleteProjectEpic,
  getProjectDetailListPrevious,
  //quotation
  getQuotationByIdEpic,
  getUserMadeInvoiceStatusEpic,
  getCaseInvoiceStatusEpic,
  getQuotationStatusEpic,
  createQuotationEpic,
  updateQuotationEpic,
  getProjectDetailByIdEpic,
  createProjectDetailsEpic,
  updateProjectDetailsEpic,
  getProjectListDetailEpic,
  getQuotationDetailDraftByIdEpic,
  //InputTrack Record
  getInputTrackRecordStatusEpic,
  getDeliveryItemUploadStatusEpic,
  getPurchaseOrderUploadStatusEpic,
  getCertificateUploadStatusEpic,
  getCompleteCertificateStatusEpic,
  getDeliveryReportStatusEpic,
  getOrderReceiptStatusEpic,
  //
  updatePurchaseOrderEpic,
  getPurchaseOrderByIdEpic,
  // issue order
  getSinglePurchaseOrderReceiptByIdEpic,
  getOrderReceiptByIdEpic,
  createOrderReceiptEpic,
  updateOrderReceiptEpic,
  sendOrderReceiptAcceptanceEpic,
  updatePurchaseOrderPlacementEpic,

  updatePurchaseOrderReceiptEpic,
  getPurchaseOrderReceiptByIdEpic,
  createPurchaseOrderPlacementEpic,

  getDeliveryNoteApproveByProjectDetailIdEpic,
  createProjectDeliveryNoteApproveEpic,
  updateProjectDeliveryNoteApproveEpic,
  //certificate
  getCertificateByIdEpic,
  updateCertificateEpic,
  //artifact
  getArtifactByIdEpic,
  createArtifactEpic,
  updateArtifactEpic,
  //certificate complete
  completeCertificateEpic,
  deleteReferenceFileIdEpic,
  deleteRequestFileIdEpic,
  createPurchaseOrderUploadEpic,

  //
  updateInspectionCertificateEpic,
  createInspectionCertificateUploadEpic,
  getInspectionCertificateUploadByIdEpic,
  updateProjectIssueInvoiceEpic,
  getInvoiceByProjectDetailIdEpic,
  createProjectIssueInvoiceEpic,
  sendJobCreateAndMailEpic,
  sendDeliveryNoteAcceptanceEpic,
  updateDeliveryWithdrawalEpic,
  //
  autoCalculateEpic,
  projectListSalesExportEpic,
  //
  getProjectWorkFlowHistoryEpic
)
