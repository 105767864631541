import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  ChangeEvent,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, Grid } from '@mui/material'

import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'

import {
  alertInfoAction,
  getCommonSaleTaxRequest,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../../ReusableComponent/CommonReportTable/CommonReportTable'
import CompanySelect from '../../../ReusableComponent/CompanySelect/CompanySelect'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import AddNewInvoiceFormBtn from '../../../ReusableComponent/MultipleUploadScreen/AddNewInvoiceFormBtn'
import CardTitle from '../../../ReusableComponent/MultipleUploadScreen/CardTitle'
import FileUploadAndWithdrawBtn from '../../../ReusableComponent/MultipleUploadScreen/FileUploadAndWithdrawBtn'
import {
  datePickerStyles,
  gridStyles,
  cardStyles,
} from '../../../ReusableComponent/MultipleUploadScreen/styles'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import WorkFlowPanel from '../../../ReusableComponent/Workflow'
import { workflowSelector } from '../../../ReusableComponent/Workflow/Redux/selector'
import WorkFlowHistoryTable from '../../../ReusableComponent/WorkflowHistory'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { downloadFile } from '../../../Utils/AppFunction'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import { defaultRowsPerPage } from '../../../Utils/globalConstant'
import { query } from '../../../Utils/globalTypes'
import {
  clearHardResetExportInvoiceHQ,
  clearInvoiceHq,
  createInvoiceHqRequest,
  getInvoiceHqByIdRequest,
  updateInvoiceHqRequest,
} from '../Redux/exportInvoiceDataSlice'
import { exportInvoiceDataListSelector } from '../Redux/selector'
import { company } from '../Redux/types'

const WORKFLOW_REJECT_STATUS = 4

export interface invoiceHqUploadProps {
  id?: number
  parent_id?: number
  company: company | null | any
  issue_date: string | Date | null
  amount: number
  tax_amount: number
  tax_excluded_amount: number
  file: string | File | null
  url: string | null
  history?: any
  workflow?: any
  invoice_no?: any
  withdraw_without_workflow_flag: boolean
  version?: number
  is_workflow_complete?: boolean
  is_workflow_rejected?: boolean
}

interface initialInvoiceHQProps {
  invoice_details: invoiceHqUploadProps[]
}

const InvoiceHq = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const invoiceDataId = searchParams.get('invoice-data-id')
  const invoicehqParentId = searchParams.get('invoicehq-parent-id')
  const edit = searchParams.get('edit')
  const [isWithdraw, setIsWithdraw] = useState(false)

  const { state } = location
  const previousUrl = state?.path
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const { defaultSalesTax } = useAppSelector(commonAppSelector)
  useLayoutEffect(() => {
    if (invoiceDataId) {
      dispatch(getInvoiceHqByIdRequest(invoiceDataId))
    } else {
      dispatch(clearInvoiceHq())
    }
    dispatch(getCommonSaleTaxRequest(defaultRowsPerPage))
    return () => {
      dispatch(clearInvoiceHq())
      dispatch(clearHardResetExportInvoiceHQ())
    }
  }, [location, invoiceDataId, dispatch])
  const { invoiceHq, loadingCreateInvoiceHq } = useAppSelector(
    exportInvoiceDataListSelector
  )

  const initialInvoiceHQData: invoiceHqUploadProps = {
    issue_date: dateFormatter(new Date()),
    company: '',
    amount: 0,
    tax_amount: 0,
    tax_excluded_amount: 0,
    file: null,
    url: '',
    invoice_no: '',
    withdraw_without_workflow_flag: false,
  }
  const initialState: initialInvoiceHQProps = {
    invoice_details:
      invoiceHq && invoiceHq?.length > 0 ? invoiceHq : [initialInvoiceHQData],
  }
  const sales_tax_default: number = defaultSalesTax?.tax_rate
  const sales_tax_default_percentage: number = sales_tax_default / 100

  const allowedTypes = ['']
  const validationSchema = Yup.object().shape({
    invoice_details: Yup.array().of(
      Yup.object().shape(
        {
          url: Yup.string().when(['file'], ([file], schema, context) => {
            return file === null
              ? schema
                  .required(t(messages.required))
                  .url(t(messages.invalidUrl))
              : schema.url(t(messages.invalidUrl))
          }),
          invoice_no: Yup.string()
            .max(20, maxCharValidation(20))
            .required(t(messages.required)),
          file: Yup.mixed().when(['url'], ([url], schema, context) => {
            return url === undefined
              ? schema
                  .required(t(messages.required))
                  .test(
                    'fileType',
                    t(messages.invalidFileType),
                    (value: any) => {
                      if (!value) {
                        return true
                      }
                      return (
                        allowedTypes.includes(value.type) ||
                        allowedTypes.includes('')
                      )
                    }
                  )
              : schema.nullable()
          }),
        },
        [['url', 'file']]
      )
    ),
  })

  const onSubmit = async (values: initialInvoiceHQProps, actions: any) => {
    const firstInvoiceDetail = values?.invoice_details[0]
    const updatedValues = {
      ...firstInvoiceDetail,
      invoice_data_export_result: invoiceDataId,
      company: firstInvoiceDetail?.company?.id ?? '',
      file: firstInvoiceDetail?.file === '' ? null : firstInvoiceDetail.file,
      issue_date: firstInvoiceDetail?.issue_date ?? '',
      version:
        (firstInvoiceDetail?.version ?? 1) < reportVersion
          ? reportVersion
          : firstInvoiceDetail?.version ?? 1,
      withdraw_without_workflow_flag:
        firstInvoiceDetail?.withdraw_without_workflow_flag
          ? firstInvoiceDetail?.withdraw_without_workflow_flag
          : isWithdraw
          ? isWithdraw
          : false,
    }

    if (!edit || (edit && !firstInvoiceDetail?.id && invoiceHq?.length)) {
      dispatch(
        createInvoiceHqRequest({
          values: updatedValues,
          parentId: invoicehqParentId,
          nav,
          actions,
          invoiceDataId,
          previousUrl,
        })
      )
    } else {
      dispatch(
        updateInvoiceHqRequest({
          values: { ...updatedValues },
          id: firstInvoiceDetail?.id,
          nav,
          actions,
          invoiceDataId,
          previousUrl,
        })
      )
    }
  }
  const focus_SaveBtn = useRef(null)

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)
  const { workflow } = useAppSelector(workflowSelector)
  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const [workFlowCompleted, setWorkFlowCompleted] = useState(false)
  const [reportVersion, setReportVersion] = useState<number>(0)
  const [isWorkflowRejected, setIsWorkflowRejected] = useState(false)
  useEffect(() => {
    if (workflow) {
      if (workflow.workflow_details.length > 0) {
        setReportVersion(workflow.workflow_details[0].report_version)
      }
      if (workflow.status === WORKFLOW_REJECT_STATUS) {
        setIsWorkflowRejected(true)
      } else {
        setIsWorkflowRejected(false)
      }
      if (workflow?.status !== 1) {
        setReadOnlyMode(true)
      } else {
        setReadOnlyMode(false)
      }
      if (workflow?.status === 3) {
        setWorkFlowCompleted(true)
      }
    } else {
      setReadOnlyMode(false)
    }
    return () => {
      setReadOnlyMode(false)
    }
  }, [workflow, invoiceHq])

  // preCalculated the values
  const DETAIL_NAME = 'invoice_details'
  const isAllInvoiceWithdrawed: boolean = useMemo(() => {
    return (
      invoiceHq &&
      invoiceHq?.length > 0 &&
      invoiceHq?.every(
        (invc: invoiceHqUploadProps) =>
          invc.withdraw_without_workflow_flag || invc.is_workflow_rejected
      )
    )
  }, [invoiceHq])

  const workFlowApprovedInvoiceId = useMemo(() => {
    return invoiceHq && invoiceHq?.length > 0
      ? invoiceHq.find(
          (invc: invoiceHqUploadProps) => invc.is_workflow_complete
        )?.workflow ?? invoiceHq[0]?.workflow
      : undefined
  }, [invoiceHq])

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          submitForm,
          setValues,
        }) => {
          const handleSave = () => {
            setIsWithdraw(false)
            submitForm()
          }

          const handleDownload = () => {
            if (values?.invoice_details[0]?.file === null) {
              dispatch(alertInfoAction(t(messages.doesntExist)))
            } else {
              downloadFile(values?.invoice_details[0]?.file)
            }
          }
          const buttonConfigs = [
            {
              label: 'common.upload',
              handler: handleSave,
              focusBtn: focus_SaveBtn,
              isShowAlert: true,
              loading: loadingCreateInvoiceHq,
              message: t('confirmMessage.uploadAndLock'),
            },
            ...(invoiceHq !== null && values?.invoice_details[0]?.file !== ''
              ? [
                  {
                    label: 'common.download',
                    handler: handleDownload,
                    isShowAlert: false,
                  },
                ]
              : []),
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              {edit && (
                <WorkFlowPanel
                  id={workFlowApprovedInvoiceId}
                  reportRejected={isAllInvoiceWithdrawed}
                />
              )}
              <CardTitle name={t('uploadRecept.invoiceHq.name')} />
              <AddNewInvoiceFormBtn
                displayAddButton={isAllInvoiceWithdrawed || isWorkflowRejected}
                onAddButtonClick={() => {
                  if (
                    values?.invoice_details?.length <= invoiceHq?.length //to only add the form once
                  ) {
                    // Update Formik's values with the new object at the beginning
                    setValues({
                      ...values,
                      invoice_details: [
                        initialInvoiceHQData,
                        ...values.invoice_details,
                      ],
                    })
                  }
                }}
              />
              <FieldArray
                name={DETAIL_NAME}
                render={() => (
                  <React.Fragment>
                    {values.invoice_details?.map(
                      (value: invoiceHqUploadProps, index: number) => {
                        const field = `${DETAIL_NAME}[${index}]`
                        const isDisabled = value?.id ? true : false
                        let isShowWithDrawButton = false
                        if (
                          value?.id &&
                          !(
                            value?.withdraw_without_workflow_flag ||
                            value?.is_workflow_rejected
                          )
                        ) {
                          isShowWithDrawButton =
                            (value?.file || value?.url) &&
                            (workFlowCompleted || !readOnlyMode)
                              ? true
                              : false
                        } else {
                          isShowWithDrawButton = false
                        }
                        return (
                          <Card
                            key={value?.id || index}
                            sx={{
                              ...cardStyles,
                              backgroundColor:
                                value?.withdraw_without_workflow_flag ||
                                value?.is_workflow_rejected
                                  ? '#D3D3D3'
                                  : '',
                            }}
                          >
                            <Grid container spacing={2} sx={gridStyles}>
                              <AppTextField
                                disabled={isDisabled}
                                xs={2.5}
                                name={`${field}.invoice_no`}
                                value={value?.invoice_no}
                                label={t('uploadRecept.invoiceHq.invoiceNo')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <CompanySelect
                                label={t('uploadRecept.invoiceHq.company')}
                                value={value?.company}
                                field={`${field}.company`}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                isDisabled={isDisabled}
                                setQuery={setQuery}
                              />
                              <AppDatePicker
                                xs={2.5}
                                name={`${field}.issue_date`}
                                label={t('uploadRecept.invoiceHq.issueDate')}
                                id={t('uploadRecept.invoiceHq.issueDate')}
                                value={value?.issue_date}
                                onChange={(value: any) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `${field}.issue_date`,
                                      dateFormatter(value)
                                    )
                                  } else {
                                    setFieldValue(`${field}.issue_date`, null)
                                  }
                                }}
                                variant="outlined"
                                sx={datePickerStyles}
                                disabled={isDisabled}
                              />
                              <AppTextField
                                xs={2.3}
                                name={`${field}.tax_excluded_amount`}
                                readOnlyMode
                                onBlur={handleBlur}
                                value={value?.tax_excluded_amount}
                                label={t(
                                  'uploadRecept.invoiceHq.taxExcludedAmount'
                                )}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                                disabled={isDisabled}
                              />
                              <AppTextField
                                xs={2.3}
                                name={`${field}.tax_amount`}
                                readOnlyJobNo
                                onBlur={handleBlur}
                                value={value?.tax_amount}
                                label={t('uploadRecept.invoiceHq.taxAmount')}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const tax_amount: number = parseFloat(
                                    e.target.value
                                  )
                                  setFieldValue(
                                    `${field}.tax_amount`,
                                    tax_amount
                                  )
                                  setFieldValue(
                                    `${field}.tax_excluded_amount`,
                                    +value?.amount - tax_amount
                                  )
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                                disabled={isDisabled}
                              />
                              <AppTextField
                                xs={2.3}
                                name={`${field}.amount`}
                                onBlur={handleBlur}
                                value={value?.amount}
                                label={t('uploadRecept.invoiceHq.amount')}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const amount: number = parseFloat(
                                    e.target.value
                                  )
                                  if (
                                    invoiceHq?.amount !== amount ||
                                    invoiceHq?.tax_amount !== value?.tax_amount
                                  ) {
                                    const tax_excluded_amount_calculated =
                                      Math.round(
                                        amount /
                                          (1 + sales_tax_default_percentage)
                                      )
                                    setFieldValue(`${field}.amount`, amount)
                                    setFieldValue(
                                      `${field}.tax_amount`,
                                      amount - tax_excluded_amount_calculated
                                    )
                                    setFieldValue(
                                      `${field}.tax_excluded_amount`,
                                      tax_excluded_amount_calculated
                                    )
                                  }
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                                disabled={isDisabled}
                              />
                            </Grid>

                            <FileUploadAndWithdrawBtn
                              edit={edit}
                              value={value}
                              index={index}
                              field={field}
                              allowedTypes={allowedTypes}
                              setFieldValue={setFieldValue}
                              disableFileDropZone={isDisabled}
                              showFileDownloadIcon={isDisabled}
                              showWithdrawButton={isShowWithDrawButton}
                              handleWithdrawalSubmit={async () => {
                                setIsWithdraw(true)
                                await submitForm()
                              }}
                            />
                          </Card>
                        )
                      }
                    )}
                  </React.Fragment>
                )}
              />
              <FocusElementOnInvalidValidation />
            </>
          )
        }}
      </Formik>
      {edit && (
        <>
          <WorkFlowHistoryTable />
        </>
      )}
    </div>
  )
}

export default InvoiceHq
