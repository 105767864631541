import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, Grid } from '@mui/material'

import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusTranslatorCommon,
  onChangeInputTranslatorCommon,
} from '../../CommonAppRedux/commonAppFunctions'
import { alertInfoAction } from '../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../CommonAppRedux/selector'
import AppAutoComplete from '../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../ReusableComponent/CommonReportTable/CommonReportTable'
import {
  useAppDispatch,
  useAppSelector,
} from '../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import AddNewInvoiceFormBtn from '../../ReusableComponent/MultipleUploadScreen/AddNewInvoiceFormBtn'
import CardTitle from '../../ReusableComponent/MultipleUploadScreen/CardTitle'
import FileUploadAndWithdrawBtn from '../../ReusableComponent/MultipleUploadScreen/FileUploadAndWithdrawBtn'
import {
  datePickerStyles,
  gridStyles,
  cardStyles,
} from '../../ReusableComponent/MultipleUploadScreen/styles'
import SaveFooterComponent from '../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { dateFormatter } from '../../Utils/AppFormatFunction'
import { downloadFile } from '../../Utils/AppFunction'
import messages from '../../Utils/ValidationMessage'
import { query } from '../../Utils/globalTypes'
import {
  clearDataJobListDetails,
  clearHardResetJobList,
  clearTranslatorInvoiceUpload,
  createTranslatorInvoiceUploadRequest,
  getJobTaskByIdRequest,
  getTranslatorInvoiceUploadByCertIdRequest,
  updateTranslatorInvoiceUploadRequest,
} from '../JobList/Redux/jobListSlice'
import { jobListSelector } from '../JobList/Redux/selector'
import { waitingBillListSelector } from './Redux/selector'
import {
  initialTranslatorInvoiceUploadProps,
  multipleTranslatorInvoiceUploadProps,
} from './Redux/types'
import { getTranslatorCertificateTaxSubtotalRequest } from './Redux/waitingBillListSlice'

const TranslatorInvoiceUpload = () => {
  const {
    translatorInvoiceUpload,
    jobTask,
    loadingCreateTranslatorInvoiceUpload,
  } = useAppSelector(jobListSelector)
  const { translatorCertificateTaxSubtotal, waitingBillRowData } =
    useAppSelector(waitingBillListSelector)
  const { commonTranslator } = useAppSelector(commonAppSelector)
  const { t } = useTranslation()
  const nav = useNavigate()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const jobTaskId = searchParams.get('job-task')
  const translatorCertificateId =
    jobTask?.translator_certificate?.translator_certificate
  const edit = searchParams.get('edit')
  const [isWithdraw, setIsWithdraw] = useState(false)
  const { state } = location
  const previousUrl = state?.path
  const dispatch = useAppDispatch()
  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    handleFocusTranslatorCommon(dispatch)
    if (jobTaskId) {
      dispatch(getJobTaskByIdRequest(jobTaskId))
    } else {
      dispatch(clearDataJobListDetails())
      dispatch(clearHardResetJobList())
    }
    return () => {
      dispatch(clearHardResetJobList())
    }
  }, [jobTaskId, location, dispatch, edit])

  useLayoutEffect(() => {
    if (jobTask?.translator_certificate?.translator_certificate) {
      dispatch(
        getTranslatorInvoiceUploadByCertIdRequest(
          jobTask?.translator_certificate?.translator_certificate
        )
      )
      dispatch(
        getTranslatorCertificateTaxSubtotalRequest(
          jobTask?.translator_certificate?.translator_certificate
        )
      )
    }
    return () => {
      dispatch(clearTranslatorInvoiceUpload())
    }
  }, [jobTask, location, dispatch, edit])

  //all reducer action and clear function implement End
  const fixed_certificate_no = useMemo(() => {
    return jobTask?.translator_certificate?.certificate_no ?? ''
  }, [jobTask])

  const initialTranslatorInvoiceData: initialTranslatorInvoiceUploadProps = {
    certificate_no: fixed_certificate_no,
    issue_date: dateFormatter(new Date()),
    translator: jobTask?.translator ?? null,
    amount: translatorCertificateTaxSubtotal ?? 0,
    file: null,
    url: '',
    version: 1,
  }
  const initialState: multipleTranslatorInvoiceUploadProps = {
    invoice_details:
      translatorInvoiceUpload && translatorInvoiceUpload.length > 0
        ? translatorInvoiceUpload
        : [initialTranslatorInvoiceData],
  }
  const allowedTypes = ['']
  const validationSchema = Yup.object().shape({
    invoice_details: Yup.array().of(
      Yup.object().shape(
        {
          translator: Yup.object().required(t(messages.required)),
          url: Yup.string().when(['file'], ([file], schema, context) => {
            return file === null
              ? schema
                  .required(t(messages.required))
                  .url(t(messages.invalidUrl))
              : schema.url(t(messages.invalidUrl))
          }),
          file: Yup.mixed().when(['url'], ([url], schema, context) => {
            return url === undefined
              ? schema
                  .required(t(messages.required))
                  .test(
                    'fileType',
                    t(messages.invalidFileType),
                    (value: any) => {
                      if (!value) {
                        return true
                      }
                      return (
                        allowedTypes.includes(value.type) ||
                        allowedTypes.includes('')
                      )
                    }
                  )
              : schema.nullable()
          }),
        },
        [['url', 'file']]
      )
    ),
  })

  const onSubmit = async (
    values: multipleTranslatorInvoiceUploadProps,
    actions: any
  ) => {
    const firstInvoiceDetail = values.invoice_details[0]
    const updatedValues = {
      ...firstInvoiceDetail,
      job_task: jobTaskId,
      translator: firstInvoiceDetail?.translator?.id ?? '',
      file: firstInvoiceDetail?.file === '' ? null : firstInvoiceDetail.file,
      issue_date: firstInvoiceDetail?.issue_date
        ? firstInvoiceDetail?.issue_date
        : '',
      translator_certificate: translatorCertificateId,
      version: firstInvoiceDetail?.version ?? 1,
      withdrawal_flag: firstInvoiceDetail?.withdrawal_flag
        ? firstInvoiceDetail?.withdrawal_flag
        : isWithdraw
        ? isWithdraw
        : false,
    }
    if (
      !edit ||
      (edit && !firstInvoiceDetail.id && translatorInvoiceUpload.length)
    ) {
      dispatch(
        createTranslatorInvoiceUploadRequest({
          values: updatedValues,
          nav,
          actions,
          previousUrl,
        })
      )
    } else {
      dispatch(
        updateTranslatorInvoiceUploadRequest({
          values: updatedValues,
          id: firstInvoiceDetail.id,
          nav,
          actions,
          previousUrl,
        })
      )
    }
  }
  const focus_SaveBtn = useRef(null)

  const getTranslatorOptionLabel = (option: any) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    // Add "xxx" option created dynamically
    if (option.name) {
      return option.name
    }
    // Regular option
    return option.inputValue
  }

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)
  // preCalculated the values
  const DETAIL_NAME = 'invoice_details'
  const isAllInvoiceWithdrawed: boolean = useMemo(() => {
    return (
      translatorInvoiceUpload &&
      translatorInvoiceUpload?.length > 0 &&
      translatorInvoiceUpload?.every(
        (invc: initialTranslatorInvoiceUploadProps) => invc.withdrawal_flag
      )
    )
  }, [translatorInvoiceUpload])
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          submitForm,
          setValues,
        }) => {
          const handleSave = () => {
            setIsWithdraw(false)
            submitForm()
          }
          const handleDownload = () => {
            if (values.invoice_details[0]?.file === null) {
              dispatch(alertInfoAction(t(messages.doesntExist)))
            } else {
              downloadFile(values.invoice_details[0]?.file)
            }
          }
          const buttonConfigs = [
            {
              label: 'common.upload',
              handler: handleSave,
              focusBtn: focus_SaveBtn,
              isShowAlert: true,
              loading: loadingCreateTranslatorInvoiceUpload,
              message: t('confirmMessage.uploadAndLock'),
            },
            ...(translatorInvoiceUpload !== null &&
            values.invoice_details[0]?.file !== ''
              ? [
                  {
                    label: 'common.download',
                    handler: handleDownload,
                    isShowAlert: false,
                  },
                ]
              : []),
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              <CardTitle
                name={t('uploadRecept.translatorInvoiceUpload.name')}
              />
              <AddNewInvoiceFormBtn
                displayAddButton={isAllInvoiceWithdrawed}
                onAddButtonClick={() => {
                  if (
                    values.invoice_details.length <=
                    translatorInvoiceUpload?.length //to only add the form once
                  ) {
                    setValues({
                      ...values,
                      invoice_details: [
                        initialTranslatorInvoiceData,
                        ...values.invoice_details,
                      ],
                    })
                  }
                }}
              />
              <FieldArray
                name={DETAIL_NAME}
                render={() => (
                  <React.Fragment>
                    {values.invoice_details?.map(
                      (
                        value: initialTranslatorInvoiceUploadProps,
                        index: number
                      ) => {
                        const field = `${DETAIL_NAME}[${index}]`
                        const isDisabled =
                          value?.withdrawal_flag || value?.id ? true : false
                        const isShowWithDrawButton =
                          (value?.file || value?.url) &&
                          !value?.withdrawal_flag &&
                          value?.id
                            ? true
                            : false
                        return (
                          <Card
                            key={value?.id || index}
                            sx={{
                              ...cardStyles,
                              bgcolor: value?.withdrawal_flag
                                ? '#D3D3D3'
                                : '#d7e2ea',
                            }}
                          >
                            <Grid container spacing={2} sx={gridStyles}>
                              <AppTextField
                                xs={2.5}
                                name={`certificate_no`}
                                disabled
                                onBlur={handleBlur}
                                value={fixed_certificate_no}
                                label={t(
                                  'uploadRecept.translatorInvoiceUpload.certificateNo'
                                )}
                                onChange={handleChange}
                              />
                              <AppAutoComplete
                                xs={2.5}
                                options={commonTranslator}
                                disabled={isDisabled}
                                value={value?.translator}
                                getOptionLabel={getTranslatorOptionLabel}
                                label={t(
                                  'uploadRecept.translatorInvoiceUpload.translator'
                                )}
                                name={`${field}.translator`}
                                onChange={(_: object, value: any) => {
                                  if (value !== null) {
                                    setFieldValue(`${field}.translator`, value)
                                  } else {
                                    setFieldValue(`${field}.translator`, null)
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusTranslatorCommon(dispatch)
                                }
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputTranslatorCommon(
                                    e.target,
                                    setQuery
                                  )
                                }
                              />
                              <AppDatePicker
                                xs={2.5}
                                name={`${field}.issue_date`}
                                label={t(
                                  'uploadRecept.translatorInvoiceUpload.issueDate'
                                )}
                                disabled={isDisabled}
                                value={value?.issue_date}
                                onChange={(value: any) => {
                                  setFieldValue(
                                    `${field}.issue_date`,
                                    dateFormatter(value)
                                  )
                                }}
                                variant="outlined"
                                sx={datePickerStyles}
                              />
                              <AppTextField
                                xs={2.5}
                                name={`${field}.amount`}
                                onBlur={handleBlur}
                                value={value?.amount}
                                label={t(
                                  'uploadRecept.translatorInvoiceUpload.amount'
                                )}
                                disabled={isDisabled}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                              />
                            </Grid>
                            <FileUploadAndWithdrawBtn
                              edit={edit}
                              value={value}
                              index={index}
                              field={field}
                              allowedTypes={allowedTypes}
                              setFieldValue={setFieldValue}
                              disableFileDropZone={isDisabled}
                              showFileDownloadIcon={
                                isDisabled || value?.id ? true : false
                              }
                              showWithdrawButton={isShowWithDrawButton}
                              handleWithdrawalSubmit={async () => {
                                setIsWithdraw(true)
                                await submitForm()
                              }}
                            />
                          </Card>
                        )
                      }
                    )}
                  </React.Fragment>
                )}
              />
              <FocusElementOnInvalidValidation />
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default TranslatorInvoiceUpload
