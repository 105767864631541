import { Suspense, lazy, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Grid, Typography } from '@mui/material'

import { FieldArray, Form, Formik } from 'formik'
import { t } from 'i18next'
import * as Yup from 'yup'

import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { initialDuplicateValues } from '../../../ReusableComponent/CustomHooks/useDuplicateData'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { formateNumberAgain } from '../../../Utils/AppFormatFunction'
import { todayDate } from '../../../Utils/AppFunction'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import { globalDuplicateValue } from '../../../Utils/globalTypes'
import {
  clearDataJobListDetails,
  clearHardResetJobList,
  clearJobDetailRow,
  createJobDetailRowByIdRequest,
  createJobDetailsRequest,
  getJobDetailRowByIdRequest,
  getJobListDetailByIdRequest,
  updateJobDetailRowByIdRequest,
  updateJobDetailsRequest,
} from '../Redux/jobListSlice'
import { jobListSelector } from '../Redux/selector'
import { CreateJobProps } from '../Types'

const JobForm = lazy(() => import('./JobForm'))
const CreateJob = () => {
  // const { id, edit } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const edit = searchParams.get('edit')
  const jobDetail = searchParams.get('job-detail')
  const createDetail = searchParams.get('create-detail')

  const { state } = location
  const previousUrl = state?.path
  const { jobListDetail, loadingCreate, loadingUpdate, jobDetailRow } =
    useAppSelector(jobListSelector)
  const dispatch = useAppDispatch()
  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    if (id) {
      dispatch(getJobListDetailByIdRequest(id))
    } else {
      dispatch(clearDataJobListDetails())
      dispatch(clearHardResetJobList())
    }

    return () => {
      dispatch(clearHardResetJobList())
    }
  }, [id, location, dispatch, edit])
  useLayoutEffect(() => {
    if (jobDetail) {
      dispatch(getJobDetailRowByIdRequest(jobDetail))
    } else {
      dispatch(clearJobDetailRow())
    }
  }, [jobDetail, location, dispatch, edit])
  console.log(
    jobDetailRow,
    'jobDetailRowjobDetailRowjobDetailRowjobDetailRowjobDetailRowjobDetailRow'
  )
  const updateDetailsValue = jobListDetail?.job_details?.map((row: any) => {
    const {
      status,
      create_date,
      update_date,
      update_user,
      create_user,
      ...newRow
    } = row

    return {
      ...newRow,
      // layout: row?.layout ?? null,
      task_time: row?.task_time ?? 0,
      unit_price: row?.unit_price ?? 0,
      expense: row?.expense ?? 0,
      cost: row?.cost ?? 0,
      total_cost: row?.total_cost ?? 0,
      selling_price: row?.selling_price ?? 0,
      page_count: row?.page_count ?? 0,
      word_count: row?.word_count ?? 0,
      rate: row?.rate ?? 0,
      sheets_count: row?.sheets_count ?? 0,
    }
  })

  // 'updateDetailsValue' now contains the updated objects with the 'status' property deleted and default values for other properties.

  const initialState: CreateJobProps = {
    job_no: edit ? jobListDetail?.job_no ?? jobDetailRow?.job_no ?? '' : '',
    recycling_no: edit
      ? jobListDetail?.recycling_no ?? jobDetailRow?.recycling_no ?? ''
      : '',
    delete_flag: false,
    job_details: jobDetailRow
      ? [jobDetailRow]
      : updateDetailsValue ?? [
          {
            detail_no: '',
            subject: '',
            page_count: 0,
            word_count: 0,
            rate: 0,
            sheets_count: 0,
            layout: null,
            delivery_date: todayDate,
            task_type: '',
            task_time: 0,
            unit_price: 0,
            expense: 0,
            cost: 0,
            total_cost: 0,
            selling_price: 0,
            artifact_files: [
              {
                seq: 0,
                file: '',
              },
            ],
          },
        ],
  }

  const validationSchema = Yup.object().shape({
    // recycling_no: Yup.string().max(1, maxCharValidation(1)),
    job_details: Yup.array().of(
      Yup.object().shape({
        layout: Yup.object().nullable(),
        detail_no: Yup.string().max(20, maxCharValidation(20)).nullable(),
        // .required(t(messages.required))
        subject: Yup.string()
          .max(400, maxCharValidation(400))
          .required(t(messages.required)),
        task_type: Yup.string().max(10, maxCharValidation(10)).nullable(),
        // task_time: Yup.mixed()
        //   .nullable()
        //   .test(
        //     'is-decimal',
        //     t(messages.atMost3CharAfterDecimal),
        //     (val: any) => {
        //       let convertedNum = formateNumberAgain(val)

        //       if (convertedNum != undefined) {
        //         return patternThreeDigisAfterComma.test(convertedNum)
        //       }
        //       return true
        //     }
        //   ),
      })
    ),
  })

  const nav = useNavigate()
  const onSubmit = async (values: CreateJobProps, actions: any) => {
    const { job_no, job_details } = values
    const rowValues = job_details?.map(({ status, ...row }: any) => ({
      ...row,
      layout: row?.layout?.id as number,
      cost: formateNumberAgain(row?.cost),
      expense: formateNumberAgain(row?.expense),
      page_count: formateNumberAgain(row?.page_count),
      rate: formateNumberAgain(row?.rate),
      selling_price: formateNumberAgain(row?.selling_price),
      sheets_count: formateNumberAgain(row?.sheets_count),
      task_time: formateNumberAgain(row?.task_time),
      total_cost: formateNumberAgain(row?.total_cost),
      unit_price: formateNumberAgain(row?.unit_price),
      word_count: formateNumberAgain(row?.word_count),
    }))
    const updatedValues = {
      ...values,
      id: jobListDetail?.id ?? undefined,
      job_no: job_no,
      job_details: rowValues,
      version: jobListDetail?.version ?? 1,
    }
    if (!jobDetail && jobListDetail?.id && edit) {
      dispatch(
        updateJobDetailsRequest({
          id: jobListDetail?.id,
          values: updatedValues,
          nav: nav,
          actions,
          previousUrl,
        })
      )
    } else if (!jobDetail) {
      dispatch(
        createJobDetailsRequest({
          values: updatedValues,
          nav: nav,
          actions,
          previousUrl,
        })
      )
    } else if (jobDetail && createDetail) {
      const updatedJobDetailValues = {
        ...rowValues[0],
        job_no: job_no,
        recycling_no: jobDetailRow?.recycling_no,
        // : rowValues[0],
      }
      dispatch(
        createJobDetailRowByIdRequest({
          values: updatedJobDetailValues,
          nav: nav,
          // actions,
          // previousUrl,
        })
      )
    } else if (jobDetail) {
      const updatedJobDetailValues = {
        ...rowValues[0],
        // id:undefined,
        job_no: job_no,
        recycling_no: jobDetailRow?.recycling_no,
        // : rowValues[0],
      }
      dispatch(
        updateJobDetailRowByIdRequest({
          id: jobDetail,
          values: updatedJobDetailValues,
          // nav: nav,
          // actions,
          // previousUrl,
        })
      )
    }
  }
  //
  const [duplicateValue, setDuplicateValue] = useState<globalDuplicateValue>(
    initialDuplicateValues
  )
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const handleSave = () => {
            formik.submitForm()
          }
          const buttonConfigs = [
            {
              label: `${edit ? 'common.update' : 'common.save'}`,
              handler: handleSave,
              isShowAlert: true,
              loading: edit ? loadingUpdate : loadingCreate,
              loadingUpdate,
              message: edit
                ? t('confirmMessage.update')
                : t('confirmMessage.save'),
            },
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ padding: '16px' }}
              >
                {edit ? t('moduleName.job.edit') : t('moduleName.job.create')}
              </Typography>
              <Form autoComplete="off" noValidate>
                <Suspense fallback={<div>Loading...</div>}>
                  <JobForm
                    formik={formik}
                    setDuplicateValue={setDuplicateValue}
                    duplicateValue={duplicateValue}
                    FieldArray={FieldArray}
                    jobDetail={jobDetail}
                  />
                </Suspense>
                <FocusElementOnInvalidValidation />
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default CreateJob
