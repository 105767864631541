import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, Grid } from '@mui/material'

import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusCostSharingDepartmentCodeCommon,
  handleFocusExpenseTypeCommon,
  onChangeInputCostSharingDepartmentCodeCommon,
  onChangeInputExpenseTypeCommon,
} from '../../../CommonAppRedux/commonAppFunctions'
import { alertInfoAction } from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { authSelector } from '../../../Login/Redux/selector'
import AppAutoComplete from '../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../../ReusableComponent/CommonReportTable/CommonReportTable'
import CompanySelect from '../../../ReusableComponent/CompanySelect/CompanySelect'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import AddNewInvoiceFormBtn from '../../../ReusableComponent/MultipleUploadScreen/AddNewInvoiceFormBtn'
import CardTitle from '../../../ReusableComponent/MultipleUploadScreen/CardTitle'
import FileUploadAndWithdrawBtn from '../../../ReusableComponent/MultipleUploadScreen/FileUploadAndWithdrawBtn'
import {
  cardStyles,
  datePickerStyles,
  gridStyles,
} from '../../../ReusableComponent/MultipleUploadScreen/styles'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { downloadFile } from '../../../Utils/AppFunction'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import { query } from '../../../Utils/globalTypes'
import { company } from '../../JobList/Types'
import {
  clearDataProjectDetails,
  clearHardResetProjectList,
  clearInspectionCertificateUploadDetails,
  createInspectionCertificateUploadRequest,
  getInspectionCertificateByIdRequest,
  getProjectDeliveryNoteApproveByIdRequest,
  getProjectDetailByIdRequest,
  updateInspectionCertificateUploadRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'
import { expenseType } from '../Types'

export interface certificate {
  // discard_flag: boolean
  file: string | File | null
  certificate_no: number
  issue_date: string | Date | null
  amount: number
  date_recorded: string | Date | null
  cost_sharing_department_code1: any
  production_order_no1: string
  cost_sharing_department_code2: any
  certificate_completion_date: string | Date | null
  production_order_no2: string
  expense_type: expenseType | null
  case?: number
  job?: number
  url: string
  company: company | null
  version?: number
  withdrawal_flag?: boolean
  id?: number
}

interface multipleCertificateProps {
  cert_details: certificate[]
}

const InspectionCertificateUpload = () => {
  const {
    inspectionCertificateUpload,
    projectDetail,
    loadingUploadInspectionCertificateUpload,
    ProjectDeliveryNoteApprove,
  } = useAppSelector(projectListSelector)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  // const { id, edit } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { state } = location
  const previousUrl = state?.path

  const id = searchParams.get('case-id')
  const edit = searchParams.get('edit')
  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    if (id) {
      dispatch(getProjectDetailByIdRequest(id))
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
    }
    return () => {
      dispatch(clearHardResetProjectList())
    }
  }, [id, location, dispatch, edit])

  useLayoutEffect(() => {
    dispatch(getInspectionCertificateByIdRequest(Number(id)))
    if (projectDetail?.case_report_relation?.delivery_note) {
      dispatch(
        getProjectDeliveryNoteApproveByIdRequest(
          projectDetail?.case_report_relation?.delivery_note
        )
      )
    }
  }, [id, projectDetail, location, dispatch, edit])

  //all reducer action and clear function implement End
  const initialCertificateUploadData: certificate = {
    version: 1,
    company: ProjectDeliveryNoteApprove?.company ?? null,
    file: null,
    url: '',
    certificate_no: 0,
    issue_date: dateFormatter(new Date()),
    amount: 0,
    date_recorded: dateFormatter(new Date()),
    cost_sharing_department_code1: null,
    production_order_no1: '',
    cost_sharing_department_code2: null,
    certificate_completion_date: '',
    production_order_no2: '',
    expense_type: null,
    withdrawal_flag: false,
  }
  const allowedTypes = ['']
  const initialState: multipleCertificateProps = {
    cert_details:
      inspectionCertificateUpload && inspectionCertificateUpload.length > 0
        ? inspectionCertificateUpload
        : [initialCertificateUploadData],
  }

  const validationSchema = Yup.object().shape({
    cert_details: Yup.array().of(
      Yup.object().shape(
        {
          certificate_no: Yup.string()
            .max(20, maxCharValidation(20))
            .nullable(),
          issue_date: Yup.mixed().required(t(messages.required)),
          date_recorded: Yup.mixed().required(t(messages.required)),
          expense_type: Yup.object().required(t(messages.required)),
          cost_sharing_department_code1: Yup.object()
            .shape({
              production_order_no_required_flag: Yup.boolean().optional(),
            })
            .nullable(),
          cost_sharing_department_code2: Yup.object()
            .shape({
              production_order_no_required_flag: Yup.boolean().optional(),
            })
            .nullable(),
          production_order_no1: Yup.string()
            .max(20, maxCharValidation(20))
            .when(
              [
                'cost_sharing_department_code1.production_order_no_required_flag',
              ],
              (flag, schema) => {
                console.log(flag, 'flagflagflagflagflag')
                return flag[0]
                  ? schema.required(t('validationMessage.costSharingRequired1'))
                  : schema
              }
            ),
          production_order_no2: Yup.string()
            .max(20, maxCharValidation(20))
            .when(
              [
                'cost_sharing_department_code2.production_order_no_required_flag',
              ],
              (flag, schema) => {
                console.log(flag, 'flagflagflagflagflag')
                return flag[0]
                  ? schema.required(t('validationMessage.costSharingRequired2'))
                  : schema
              }
            ),
          url: Yup.string().when(['file'], ([file], schema, context) => {
            return file === null || file === undefined
              ? schema
                  .required(t(messages.required))
                  .url(t(messages.invalidUrl))
              : schema.url(t(messages.invalidUrl)).nullable()
          }),
          file: Yup.mixed().when(['url'], ([url], schema, context) => {
            return url === undefined
              ? schema
                  .required(t(messages.required))
                  .test(
                    'fileType',
                    t(messages.invalidFileType),
                    (value: any) => {
                      if (!value) {
                        return true
                      }
                      return (
                        allowedTypes.includes(value.type) ||
                        allowedTypes.includes('')
                      )
                    }
                  )
              : schema.nullable()
          }),
        },
        [['url', 'file']]
      )
    ),
  })

  const navigate = useNavigate()
  const [issueInvoiceBtn, setIssueInvoiceBtn] = useState(false)
  const [isWithdraw, setIsWithdraw] = useState(false)

  const onSubmit = async (values: multipleCertificateProps) => {
    const firstCertificateData = values.cert_details[0]
    const {
      expense_type,
      cost_sharing_department_code1,
      cost_sharing_department_code2,
    } = firstCertificateData
    const updateValue = {
      ...firstCertificateData,
      case: Number(id),
      certificate_no: firstCertificateData?.certificate_no ?? '',
      job: projectDetail?.job?.id ?? '',
      cost_sharing_department_code1: cost_sharing_department_code1?.id ?? '',
      cost_sharing_department_code2: cost_sharing_department_code2?.id ?? '',
      expense_type: expense_type?.id,
      company: firstCertificateData?.company?.id ?? '',
      file:
        firstCertificateData?.file === '' ? null : firstCertificateData.file,
      url: firstCertificateData?.url ?? '',
      withdrawal_flag: firstCertificateData?.withdrawal_flag
        ? firstCertificateData?.withdrawal_flag
        : isWithdraw
        ? isWithdraw
        : false,
      version: firstCertificateData?.version ?? 1,
    }
    if (!edit || (edit && !firstCertificateData.id)) {
      dispatch(
        createInspectionCertificateUploadRequest({
          values: updateValue,
          caseId: Number(id),
          navigate: !issueInvoiceBtn ? navigate : null,
          previousUrl: previousUrl,
        })
      )
    } else {
      dispatch(
        updateInspectionCertificateUploadRequest({
          id: firstCertificateData?.id,
          values: updateValue,
          caseId: Number(id),
          navigate: !issueInvoiceBtn ? navigate : null,
          previousUrl: previousUrl,
        })
      )
    }

    if (issueInvoiceBtn) {
      const { case_report_relation, id } = projectDetail
      if (case_report_relation?.invoice) {
        const queryParams = `?case-id=${id}&edit=${true}`
        navigate(`/case-issue-invoice${queryParams}`, {
          state: { path: location?.pathname + location?.search },
        })
      } else {
        const queryParams = `?case-id=${id}`
        navigate(`/case-issue-invoice${queryParams}`, {
          state: { path: location?.pathname + location?.search },
        })
      }
    }
  }
  const focus_SaveBtn = useRef(null)
  const { commonExpenseTypes, commonCostSharingDepartmentCodes } =
    useAppSelector(commonAppSelector)

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)
  const { is_staff } = useAppSelector(authSelector)
  const DETAIL_NAME = `cert_details`
  const isAllCertificateWithdrawed: boolean | null = useMemo(() => {
    return (
      inspectionCertificateUpload &&
      inspectionCertificateUpload?.length > 0 &&
      inspectionCertificateUpload?.every(
        (invc: certificate) => invc.withdrawal_flag
      )
    )
  }, [inspectionCertificateUpload])
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          submitForm,
          setValues,
        }) => {
          const handleSave = () => {
            setIsWithdraw(false)
            submitForm()
          }
          const handleIssueInvoice = async () => {
            if (projectDetail?.case_report_relation?.invoice) {
              const invoiceId = projectDetail?.case_report_relation?.invoice
              const queryParams = `?invoice-id=${invoiceId}&case-id=${
                projectDetail?.id
              }&edit=${true}`
              navigate(`/case-issue-invoice${queryParams}`, {
                state: { path: location?.pathname + location?.search },
              })
            } else {
              const queryParams = `?case-id=${projectDetail?.id}`
              navigate(`/case-issue-invoice${queryParams}`, {
                state: { path: location?.pathname + location?.search },
              })
            }
          }
          const handleDownload = () => {
            if (values?.cert_details[0]?.file === null) {
              dispatch(alertInfoAction(t(messages.doesntExist)))
            } else {
              downloadFile(values?.cert_details[0]?.file)
            }
          }
          const handleBackToCase = () => {
            const queryParams = `?id=${projectDetail?.id}&edit=${true}`
            navigate(`/create-agency-project${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
          const buttonConfigs = [
            ...(is_staff &&
            ['030', '040', '050', '060', '070', '080', '090'].includes(
              projectDetail?.status?.code
            )
              ? [
                  {
                    label: 'common.upload',
                    handler: handleSave,
                    focusBtn: focus_SaveBtn,
                    isShowAlert: true,
                    loading: loadingUploadInspectionCertificateUpload,
                    message: t('confirmMessage.uploadAndLock'),
                  },
                ]
              : []),
            ...(edit && is_staff
              ? [
                  {
                    label: 'common.issueInvoice',
                    handler: handleIssueInvoice,
                  },
                ]
              : []),
            ...(edit &&
            values?.cert_details[0]?.file !== '' &&
            (is_staff
              ? ['070', '080', '090'].includes(projectDetail?.status?.code)
              : ['080', '090'].includes(projectDetail?.status?.code))
              ? [
                  {
                    label: 'common.download',
                    handler: handleDownload,
                  },
                ]
              : []),
            {
              label: 'common.backToCase',
              handler: handleBackToCase,
            },
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          const isFilePresent = (id: any) => {
            if (!id) return false
            const obj = inspectionCertificateUpload?.find(
              (val: certificate) => val.id === id
            )
            return (obj?.file || obj?.url) ?? false
          }
          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>

              <CardTitle name={t('uploadRecept.certificateUpload.name')} />
              <AddNewInvoiceFormBtn
                displayAddButton={isAllCertificateWithdrawed}
                onAddButtonClick={() => {
                  if (
                    inspectionCertificateUpload &&
                    values.cert_details.length <=
                      inspectionCertificateUpload?.length
                  ) {
                    setValues({
                      ...values,
                      cert_details: [
                        initialCertificateUploadData,
                        ...values.cert_details,
                      ],
                    })
                  }
                }}
              />
              <FieldArray
                name={DETAIL_NAME}
                render={() => (
                  <React.Fragment>
                    {values.cert_details?.map(
                      (value: certificate, index: number) => {
                        const field = `${DETAIL_NAME}[${index}]`
                        const isDisabled =
                          value?.withdrawal_flag || isFilePresent(value?.id)
                            ? true
                            : false
                        const isShowWithDrawButton =
                          isFilePresent(value?.id) && !value?.withdrawal_flag
                            ? true
                            : false
                        return (
                          <Card
                            key={value?.id || index}
                            sx={{
                              ...cardStyles,
                              bgcolor: value?.withdrawal_flag ? '#D3D3D3' : '',
                            }}
                          >
                            <Grid container spacing={2} sx={gridStyles}>
                              <CompanySelect
                                label={t(
                                  'uploadRecept.certificateUpload.company'
                                )}
                                value={value?.company}
                                field={`${field}.company`}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                isDisabled={isDisabled}
                                setQuery={setQuery}
                              />
                              <AppDatePicker
                                id={t(
                                  'uploadRecept.certificateUpload.issueDate'
                                )}
                                xs={3}
                                value={value?.issue_date}
                                name={`${field}.issue_date`}
                                label={t(
                                  'uploadRecept.certificateUpload.issueDate'
                                )}
                                onChange={(value: any) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `${field}.issue_date`,
                                      dateFormatter(value)
                                    )
                                  } else {
                                    setFieldValue(`${field}.issue_date`, '')
                                  }
                                }}
                                disabled={isDisabled}
                                variant="outlined"
                                sx={datePickerStyles}
                              />
                              <AppTextField
                                xs={3}
                                name={`${field}.amount`}
                                value={value?.amount}
                                // required
                                placeholder={t(
                                  'uploadRecept.certificateUpload.amount'
                                )}
                                label={t(
                                  'uploadRecept.certificateUpload.amount'
                                )}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                                disabled={isDisabled}
                              />
                              <AppDatePicker
                                id={t(
                                  'uploadRecept.certificateUpload.recordedDate'
                                )}
                                xs={3}
                                value={value?.date_recorded}
                                name={`${field}.date_recorded`}
                                label={t(
                                  'uploadRecept.certificateUpload.recordedDate'
                                )}
                                onChange={(value: any) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `${field}.date_recorded`,
                                      dateFormatter(value)
                                    )
                                  } else {
                                    setFieldValue(`${field}.date_recorded`, '')
                                  }
                                }}
                                disabled={isDisabled}
                                variant="outlined"
                                sx={datePickerStyles}
                              />
                              <AppAutoComplete
                                xs={3}
                                options={commonCostSharingDepartmentCodes}
                                value={
                                  value?.cost_sharing_department_code1 ?? null
                                }
                                getOptionLabel={(option: any) => option.name}
                                label={t(
                                  'uploadRecept.certificateUpload.costSharingDepartmentCode1'
                                )}
                                name={`${field}.cost_sharing_department_code1`}
                                onChange={(_: object, value: any) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `${field}.cost_sharing_department_code1`,
                                      value
                                    )
                                  } else {
                                    setFieldValue(
                                      `${field}.cost_sharing_department_code1`,
                                      null
                                    )
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusCostSharingDepartmentCodeCommon(
                                    dispatch
                                  )
                                }
                                disabled={isDisabled}
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputCostSharingDepartmentCodeCommon(
                                    e.target,
                                    setQuery
                                  )
                                }
                              />

                              <AppTextField
                                xs={3}
                                name={`${field}.production_order_no1`}
                                value={value?.production_order_no1}
                                label={t(
                                  'uploadRecept.certificateUpload.productionOrderNo1'
                                )}
                                disabled={isDisabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <AppAutoComplete
                                xs={3}
                                options={commonCostSharingDepartmentCodes}
                                value={
                                  value?.cost_sharing_department_code2 ?? null
                                }
                                getOptionLabel={(option: any) => option.name}
                                label={t(
                                  'uploadRecept.certificateUpload.costSharingDepartmentCode2'
                                )}
                                name={`${field}.cost_sharing_department_code2`}
                                onChange={(_: object, value: any) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `${field}.cost_sharing_department_code2`,
                                      value
                                    )
                                  } else {
                                    setFieldValue(
                                      `${field}.cost_sharing_department_code2`,
                                      null
                                    )
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusCostSharingDepartmentCodeCommon(
                                    dispatch
                                  )
                                }
                                disabled={isDisabled}
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputCostSharingDepartmentCodeCommon(
                                    e.target,
                                    setQuery
                                  )
                                }
                              />
                              <AppTextField
                                xs={3}
                                name={`${field}.production_order_no2`}
                                value={value?.production_order_no2}
                                label={t(
                                  'uploadRecept.certificateUpload.productionOrderNo2'
                                )}
                                disabled={isDisabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <AppAutoComplete
                                xs={3}
                                options={commonExpenseTypes}
                                value={value?.expense_type ?? null}
                                getOptionLabel={(option: any) => option.name}
                                label={t('projectList.columns.expenseType')}
                                name={`${field}.expense_type`}
                                onChange={(_: object, value: any) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `${field}.expense_type`,
                                      value
                                    )
                                  } else {
                                    setFieldValue(`${field}.expense_type`, null)
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusExpenseTypeCommon(dispatch)
                                }
                                disabled={isDisabled}
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputExpenseTypeCommon(
                                    e.target,
                                    setQuery
                                  )
                                }
                              />
                              <AppTextField
                                xs={3}
                                name={`${field}.certificate_no`}
                                value={value?.certificate_no}
                                onBlur={handleBlur}
                                placeholder={t(
                                  'uploadRecept.certificateUpload.certificateNo'
                                )}
                                label={t(
                                  'uploadRecept.certificateUpload.certificateNo'
                                )}
                                disabled={isDisabled}
                                onChange={handleChange}
                              />
                            </Grid>
                            <FileUploadAndWithdrawBtn
                              edit={edit}
                              value={value}
                              index={index}
                              field={field}
                              allowedTypes={allowedTypes}
                              setFieldValue={setFieldValue}
                              disableFileDropZone={isDisabled}
                              showFileDownloadIcon={
                                isDisabled || value?.file ? true : false
                              }
                              showWithdrawButton={isShowWithDrawButton}
                              handleWithdrawalSubmit={async () => {
                                setIsWithdraw(true)
                                await submitForm()
                              }}
                            />
                          </Card>
                        )
                      }
                    )}
                  </React.Fragment>
                )}
              />
              <FocusElementOnInvalidValidation />
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default InspectionCertificateUpload
